const initialState = {
  funnelSteps: [],
  funnelList: [],
  page: {
    skip: 0,
    count: 0,
    search:"",
    key:"",
    isAscending:"",
    }, 
  filter:{},
  PublicInvoiceItems: null,
  PublicInvoiceCount: null,
  signedContracts:{
    data:[],
    page:{
        count:0,
        skip:0,
        key:"",
        isAscending:"",
    },  
  signedContract:{},
      search:"",
      filter:{},
    }
};
export default function funnelReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case "GET_FUNNEL_STEPS_S":
      return {
        ...state,
        funnelSteps: action.data??[],
      };
    case "GET_FUNNEL_STEPS_R":
      return {
        ...state,
        funnelSteps: initialState.funnelSteps,
      };
    case "GET_FUNNEL_LIST_S":
       return {
        ...state,
        funnelList: action.data?.body?.preserveData ? [...state.funnelList,...action.data.list] : action.data.list,
        page: {
          ...state.page,
          count:action.data.total,
          skip:action.data.skip,
        },
        filter:action.data.filter
      };
    case "GET_FUNNEL_LIST_R":
      return {
        ...state,
        funnelList: initialState.funnelList,
        page:initialState.page,
        filter:initialState.filter
      };
    case "GET_PUBLIC_INVOICE_LIST_S":
      return {
        ...state,
        PublicInvoiceItems: state.PublicInvoiceItems
          ? [...state.PublicInvoiceItems, ...action.data.data]
          : action.data.data,
        PublicInvoiceCount: action.data.count,
      };

    case "RESET_PUBLIC_INVOICE_LIST":
      return {
        ...state,
        PublicInvoiceItems: null,
        PublicInvoiceCount: null,
      };

      case 'CREATE_FUNNEL_SPACE_SUCCESS':
        return {
            ...state,
            funnelList: [...state.funnelList, action.data]
        };

    case 'GET_FUNNEL_SPACE_SUCCESS':
        return {
            ...state,
            funnelList: action.data 
        
        };

    case 'UPDATE_FUNNEL_SPACE_SUCCESS':
        return {
            ...state,
funnelList: state.funnelList.map(item =>
    item.id === action.data.id ? action.data : item
)
        };
    
    case 'DELETE_FUNNEL_SPACE_SUCCESS':
        return {
            ...state,
funnelList: state.funnelList.filter(item => item.id !== action.data)
        };


        case 'GET_SIGNED_CONTRACTS_R':
          return{
            ...state,
            signedContracts:initialState.signedContracts,
            signedContracts:{
              filter:initialState.signedContracts.filter
            }
            
          }

          case 'GET_SIGNED_CONTRACTS_S':
            return {
              ...state,
              signedContracts:{
               data: action.data?.params?.preserveData ? [
                    ...state.signedContracts.data,...action.data.data,
               ]:action.data.data,
                page:{
                  ...state.signedContracts.page,
                  count:action.data?.page?.count,
                  skip:action.data?.page?.skip,
                },
                search:action.data?.search,
                filter:action.data?.filter
              }
            };
            case 'RESET_FUNNEL_LIST_SKIP':{
              return {
                ...state,
                page:{
                  ...state.page,
                  skip:1
                }
              }
            }

            case 'UPDATE_FUNNEL_LIST':{
              return {
                ...state,
                funnelList:action.data
              }
            }

            case 'GET_SIGNED_CONTRACT_BY_UUID_S':{
              return {
                ...state,
                signedContract:action.data
              }
            }
    default:
      return state;
  }
}
