import React, { useState, useEffect, memo } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid, withStyles, IconButton, Popover, ListItemIcon, Radio, MenuItem, MenuList, Avatar, Divider, Badge, Link, useTheme, Tooltip, useMediaQuery, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Dialog, Snackbar, Icon } from '@material-ui/core';
import { isIOS, isBrowser } from 'react-device-detect';
import warningIcon from "../../assets/images/warningIcon.svg";

//import history
import history from "../../config/history"
import { _api } from "../../config/environment";

//Import component
import { Typography } from '../../components'

//import Images
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FaceIcon from '@material-ui/icons/Face';
import LockIcon from '@material-ui/icons/Lock';
import ReceiptIcon from '@material-ui/icons/Receipt';
import GrainIcon from '@material-ui/icons/Grain';
import ShareIcon from '@material-ui/icons/Share';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';


//import Styles
import HeaderStyles from './HeaderStyles'

//import actions
import { userAgencyAction, authAction, alertAction, profileAction, eventAction } from '../../redux/actions';
import { HeadphoneIcon, SettingsIcon, ReferralIcon, CloseIcon, ThemeIcon } from '../../assets/svg';
import { useThemeContext } from '../../assets/theme/ThemeContextProvider';
import moment from 'moment';

import { StatusBar, Style } from '@capacitor/status-bar';
import { getLocalStorage, getSystemThemePref, isFlozyMobileApp } from '../../utils';
import { MobileActionSheet } from '../../components/MobileComponents/MobileActionSheet';
import { pageBuilderAction } from '../../redux/actions/pageBuilderAction';
import customButtonStyles from '../../assets/customButton';

const SettingsPopoverContainer = memo((props) => {
    const { handleProfileAction, ag_owner, userId, classes, wsm, isOwner, theme, user_role, agNotification, handleClose, agency_pref, logout, updateUserAgency, handleClickTheme, isWSM, isClient, isAdminUser, userData, userAgenciesList, profileImage, handleSupportAction } = props;
    const [moreItems, setMoreItems] = useState(false);
    const showMoreClick = () => {
        setMoreItems(!moreItems)
    }
    return (
        <MenuList className={classes.settingsList}>
            {/* Header Menu Item */}
            <MenuItem key="userProfileItem" onClick={() => { if (agency_pref) { handleProfileAction() } }} className={classes.MenuTitle}>
                <ListItemIcon className="relative">
                    <Avatar className={classes.settingsTitleAvatar} alt={userData.first_name || userData.email || ""} src={profileImage} />
                </ListItemIcon>
                <Grid className='pl15'>
                    <Typography variant="h6" className="fw-600">{`${userData.first_name ? userData.first_name : ""} ${userData.last_name ? userData.last_name.slice(0, 10) : ""}`}</Typography>
                    <Typography variant="body1">{`${userData.email ? userData.email : ""}`}</Typography>
                </Grid>
            </MenuItem>
            <Divider />

            {
                userAgenciesList && userAgenciesList.length > 0 ?
                    <>
                        <Typography className={classes.settingsTitle} variant="h6">Workspaces</Typography>

                        <Grid className={moreItems ? classes.menuListOpen : classes.menuList}>
                            {userAgenciesList && userAgenciesList.length > 0 &&
                                userAgenciesList.map((item, key) => (
                                    <>
                                        <MenuItem key={`${item.agency_name}_${key}`} onClick={() => { updateUserAgency(item.agencyID); handleClose(); }} style={{ background: agency_pref === item.agencyID ? theme.palette.containers.bg19 : "" }}>
                                            <ListItemIcon>
                                                <Avatar className={classes.settingsListAvatar} src={item.agency_logo ? item.agency_logo : item.agency_name} alt={item.agency_name || ""} />
                                            </ListItemIcon>
                                            <Grid>
                                                <Typography variant="subtitle2">
                                                    {item.agency_name ? item.agency_name : ""}
                                                </Typography>
                                            </Grid>
                                            {
                                                agency_pref !== item.agencyID ? <Grid>
                                                    <Badge className={classes.notifiBadgeSettings} badgeContent={agNotification && item["agencyID"] && agNotification[item["agencyID"]] && agNotification[item["agencyID"]]["all"] ? agNotification[item["agencyID"]]["all"] : 0} color="primary" />
                                                </Grid> : null
                                            }
                                        </MenuItem>
                                    </>
                                ))
                            }
                        </Grid>
                        {userAgenciesList && userAgenciesList.length > 5 &&
                            <Typography variant='body2' onClick={showMoreClick} className={classes.showMoreLess}>{moreItems ? "Show less" : "Show more"}</Typography>
                        }
                        <Divider />
                    </> : null
            }

            {isOwner && (!isIOS && isBrowser) && <Typography className={`${classes.settingsTitle} pointer`} onClick={() => { handleClose(); history.push("/agencyAdd") }} variant="h6">{"+ Create New Agency"}</Typography>}
            <Divider />
            {user_role && user_role["profile"] && <MenuItem key={"setting_my_profileW"} onClick={() => { handleClose(); history.push(`/settings/profile?previousPath=${history?.location?.pathname}`) }}>
                <ListItemIcon>
                    <FaceIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    My Profile
                </Typography>
            </MenuItem>}
            {user_role && user_role["profile"] && <MenuItem key={"setting_my_profile"} onClick={() => { handleClose(); history.push(`/settings/password?previousPath=${history?.location?.pathname}`) }}>
                <ListItemIcon>
                    <LockIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    Password Settings
                </Typography>
            </MenuItem>}
            {(!isClient && (ag_owner === userId || isAdminUser)) && <MenuItem key={"setting_my_team"} onClick={() => { handleClose(); history.push("/teams") }}>
                <ListItemIcon>
                    <GrainIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    My Team
                </Typography>
            </MenuItem>}
            {isWSM && (!isIOS) && wsm && <MenuItem key={"setting_my_team"} onClick={() => { handleClose(); history.push("/wsm") }}>
                <ListItemIcon>
                    <ShareIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    Shared Workspaces
                </Typography>
            </MenuItem>}
            {ag_owner === userId && (!isIOS) &&
                <MenuItem key={"setting_my_team"} onClick={() => { handleClose(); history.push("/referral") }}>
                    <ListItemIcon>
                        <ReferralIcon />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        Referrals
                    </Typography>
                </MenuItem>}
            {ag_owner === userId && (!isIOS) && <MenuItem key={"setting_my_bills"} onClick={() => { handleClose(); history.push("/mybills") }}>
                <ListItemIcon>
                    <ReceiptIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    My Bills
                </Typography>
            </MenuItem>}
            <MenuItem key={"flozy_customer_support"} onClick={() => { handleSupportAction(); }}>
                <ListItemIcon>
                    <HeadphoneIcon />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    Chat With Us
                </Typography>
            </MenuItem>
            <MenuItem>
                <Link className="dflex alignCenter" href="https://help.flozy.com/" target={"_blank"}>
                    <ListItemIcon>
                        <LiveHelpIcon />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        Help Center
                    </Typography>
                </Link>
            </MenuItem>
            <MenuItem onClick={handleClickTheme} className={`${classes.settinglist} dflex directionRow pl-2`}>
                {/* <Grid > */}
                <ListItemIcon>
                    <ThemeIcon />
                </ListItemIcon>
                <Typography >Theme</Typography>
                {/* </Grid> */}
            </MenuItem>

            <MenuItem key={"setting_logout"} onClick={() => { handleClose(); logout() }}>
                <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    Logout
                </Typography>
            </MenuItem>
        </MenuList>
    )
})

function Settings(props) {

    const isMobile = useMediaQuery('(max-width: 599px)')
    //define dispatch
    const dispatch = useDispatch();

    const customBtnSty = customButtonStyles()

    const { publishedSites } = useSelector((p) => p.pageBuilder)
    //get props
    const { classes, isPublic, is_Client } = props;
    const theme = useTheme()

    //get reducer data
    const { userAgencies } = useSelector(s => (s.userAgency));
    const { agency_pref, ag_owner, id: userId, first_name, last_name, email, wsm, user_role } = useSelector(s => (s.auth.loginUser));
    const { profile, agency_notification: agNotification, appConfig: { isCustomDomain } } = useSelector(s => (s.profile));
    const { flags } = useSelector(s => (s.featureFlags));
    const [userAgenciesList, setUserAgenciesList] = useState([])
    const [profileImage, setProfileImage] = useState(profile && profile["avatar_filename"] ? profile["avatar_filename"] : "/static/images/avatar/1.jpg")

    const openSiteUpdateBar = JSON?.parse(getLocalStorage("updateSiteBar"));
    const [openBar, setOpenBar] = useState(openSiteUpdateBar)

    let userData = userAgencies && userAgencies.length > 0 && userAgencies[0] ? userAgencies[0] : { first_name, last_name, email, id: userId }

    const [agencyProfileImage, setAgencyProfileImage] = useState(profile && profile["agency_logo"] ? profile["agency_logo"] : null);

    useEffect(() => {
        dispatch(userAgencyAction.getUserAgencies({}))
        dispatch(profileAction.getProfile())
    }, [dispatch])

    useEffect(() => {
        setProfileImage(profile && profile["avatar_filename"] ? profile["avatar_filename"] : "/static/images/avatar/1.jpg");
        if (userId) {
            dispatch(userAgencyAction.getUserAgencies({}))
        }
    }, [profile])

    useEffect(() => {
        setAgencyProfileImage(userAgencies && userAgencies["agency_logo"] ? userAgencies["agency_logo"] : "/static/images/avatar/1.jpg");
        if (userAgencies) {
            let tempAgencyArr = [...userAgencies]
            let agencyIndex = tempAgencyArr.findIndex((agency) => agency.agencyID === agency_pref)
            if (agencyIndex >= 0) {
                let agency = tempAgencyArr[agencyIndex];
                tempAgencyArr.splice(agencyIndex, 1);
                tempAgencyArr.splice(0, 0, agency);
                setUserAgenciesList(tempAgencyArr)
            }
        }
    }, [userAgencies, agency_pref])

    //DropDown
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const { mode, toggleColorMode, selectedThemeType, isPendingChanges, saveThemeChanges } = useThemeContext();
    useEffect(() => {
        if (localStorage.getItem("themeType")) {
            localStorage.setItem('themeType', mode)
        }
        if (isFlozyMobileApp()) {
            setStatusBarStyleDark();
        }
    }, [mode])

    const setStatusBarStyleDark = async () => {
        const themeColor = localStorage.getItem("themeType") || getSystemThemePref();
        document.body.style.backgroundColor = `${theme.palette.containers.card}`;
        try {
            await StatusBar.setStyle({ style: themeColor === 'light' ? Style.Light : Style.Dark });
        } catch (err) {
            console.log(err)
        }
    };

    const handleClickSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickTheme = (event) => {
        setAnchorEl2(event.currentTarget);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseTheme = () => {
        setAnchorEl2(null);
    }
    const handleCloseIcon = () => {
        setAnchorEl2(false);
    };

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const id = open ? 'simple-popover' : undefined;
    const id2 = open2 ? 'simple-popover' : undefined;

    let isOwner = false;
    let isAdminUser = false;
    let isClient = user_role && user_role.hasOwnProperty("type") && (user_role.type === "client" || user_role.role) === 3 ? true : false;
    userAgencies?.map(i => {
        if (i["agency_owner"] === userId) {
            isOwner = true;
        }
    })

    if (user_role && ((user_role["teams"]?.includes(2) || user_role["teams"]?.includes(3)))) {
        isAdminUser = true;
    }
    //upadate agencies
    const updateUserAgency = (id) => {
        if (agency_pref !== id) {
            dispatch(userAgencyAction.updateUserAgencies({ agency_id: id }, (res) => {
                if (localStorage.getItem("emailAddress")) {
                    const params = {
                        email: '',
                        type: 'disconnect'
                    }
                    dispatch(eventAction.getGoogleEmail(params))
                    dispatch(eventAction.getAppEvents({
                        dateRange: {
                            start_date: moment(new Date()).format('yyyy-MM-DD'),
                            end_date: moment(new Date()).format('yyyy-MM-DD')
                        },
                        type: 'disconnect',
                        tokens: JSON.parse(localStorage.getItem("emailtoken"))
                    }))
                }
                localStorage.removeItem('emailAddress')
                setAnchorEl(null);

                if (res && res.message === "Updated Successfully") {
                    if (isCustomDomain) {
                        window.location.href = `${_api.appUrl}/#/dashboard`;
                    }

                    history.push("/dashboard");
                    window.location.reload();
                }
            }))
        } else {
            dispatch(alertAction.error("This agency is currently selected "));
            setAnchorEl(null);
        }
    }

    //logout application
    const logout = () => {
        localStorage.removeItem("staySignInAG");
        dispatch(authAction.logout());
    }

    const isWSM = (ag_owner == userId) && (wsm || flags?.wsm);

    const handleProfileAction = () => {
        handleClose();
        history.push(`/settings/profile?previousPath=${history?.location?.pathname}`)
    }

    const handleWarningIcon = () => {
        localStorage.setItem('updateSiteBar', true)
        setOpenBar(true)
    }

    const handleSupportAction = () => {
        handleClose();
        window?.fcWidget?.show();
        window?.fcWidget?.open();
    }

    //Remove RenderUpdateSiteBar after all site are updated
    const RenderUpdateSiteBar = (props) => {
        const { publishedSites } = props
        const handleSiteUpdate = () => {
            history.push(`/page-builder/site/${publishedSites[0]?.site_id}`)
            setOpenBar(false)
        }
        const handleSiteUpdateClose = () => {
            localStorage.setItem('updateSiteBar', false)
            setOpenBar(false)
        }

        return (
            !isMobile && (
                <Grid container alignItems="center" direction="row" spacing={1} style={{ position: 'relative' }}>
                    <Grid item>
                        <Icon><img src={warningIcon} style={{ height: '30px' }} alt="warning icon" /></Icon>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography className="fs-16 fw-700">Kindly update your domain settings.</Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton style={{
                                            position: 'absolute',
                                            top: '-14px',
                                            left: '-40px',
                                        }}
                                            onClick={handleSiteUpdateClose}><CloseIcon /></IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography className="fs-12 fw-400 secondary1">Updating is essential for your website to function properly.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button className={`${customBtnSty?.primaryButton} fs-12`} onClick={handleSiteUpdate}>Click Here</Button>
                    </Grid>
                </Grid>
            )
        )
    }

    return (
        <React.Fragment>
            <Tooltip title={"Settings"}><IconButton className="ml15" onClick={handleClickSettings}><SettingsIcon /></IconButton></Tooltip>
            {isMobile ?
                <MobileActionSheet
                    open={open}
                    title=''
                    handleCloseAction={handleClose}>
                    <SettingsPopoverContainer
                        classes={classes}
                        handleProfileAction={handleProfileAction}
                        handleSupportAction={handleSupportAction}
                        // showMoreClick={showMoreClick}
                        logout={logout}
                        ag_owner={ag_owner}
                        updateUserAgency={updateUserAgency}
                        handleClickTheme={handleClickTheme}
                        isWSM={isWSM}
                        isClient={isClient}
                        // moreItems={moreItems}
                        handleClose={handleClose}
                        isAdminUser={isAdminUser}
                        userData={userData}
                        userAgenciesList={userAgenciesList}
                        profileImage={profileImage}
                        agency_pref={agency_pref}
                        theme={theme}
                        userId={userId}
                        user_role={user_role}
                        isOwner={isOwner}
                        agencyProfileImage={agencyProfileImage}
                        agNotification={agNotification}
                        wsm={wsm}
                    />
                    {/* {SettingsPopoverContainer} */}
                </MobileActionSheet>
                :
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    className={classes.settingsPopover}
                >
                    <SettingsPopoverContainer
                        classes={classes}
                        handleProfileAction={handleProfileAction}
                        handleSupportAction={handleSupportAction}
                        // showMoreClick={showMoreClick}
                        logout={logout}
                        ag_owner={ag_owner}
                        updateUserAgency={updateUserAgency}
                        handleClickTheme={handleClickTheme}
                        isWSM={isWSM}
                        isClient={isClient}
                        // moreItems={moreItems}
                        handleClose={handleClose}
                        isAdminUser={isAdminUser}
                        userData={userData}
                        userAgenciesList={userAgenciesList}
                        profileImage={profileImage}
                        agency_pref={agency_pref}
                        theme={theme}
                        userId={userId}
                        user_role={user_role}
                        isOwner={isOwner}
                        agencyProfileImage={agencyProfileImage}
                        agNotification={agNotification}
                        wsm={wsm}
                    />
                    {/* {SettingsPopoverContainer} */}
                </Popover>
            }
            <Dialog
                id={id2}
                open={open2}
                anchorEl={anchorEl2}
                onClose={handleCloseTheme}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{ overflow: 'hidden' }}
                className={classes.themePopover}
            >
                <Grid container className={classes.createTheme}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Typography variant="body1" className="fw-600">Select Theme</Typography>
                            <IconButton className="closeIcon" onClick={handleCloseIcon}>
                                <CloseIcon />
                            </IconButton>
                            <Grid item xs={12} className={classes.themepopup}>
                                <FormControl>
                                    <RadioGroup value={selectedThemeType} onChange={(event) => toggleColorMode(event.target.value)}>
                                        <FormControlLabel value="light" control={<Radio />} label="Light" />
                                        <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                                        <FormControlLabel value="system" control={<Radio />} label="System Default" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className={classes.themebutton}>
                            <Button
                                className={`${classes.saveBtn}`}
                                onClick={() => {
                                    saveThemeChanges();
                                    handleCloseTheme();
                                }}
                                disabled={!isPendingChanges}
                                variant="contained"
                                color="primary"
                                size="large"
                                title="CANCEL"
                            >
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            {
                publishedSites?.length > 0 &&
                <>
                    {openBar ?
                        <Snackbar
                            open={true}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            message={<RenderUpdateSiteBar publishedSites={publishedSites} />}
                            className={classes.warningSnackBarStyles}
                        />
                        :
                        <IconButton onClick={handleWarningIcon} className={`${classes.warningIcon} blob yellow`}><img src={warningIcon} style={{ height: '25px', position: 'absolute', top: '1px' }} alt="warning icon" /></IconButton>
                    }
                </>
            }

        </React.Fragment >

    )

}

// default props
Settings.defaultProps = {
    classes: {},
    user_role: {}
};

// prop types
Settings.propTypes = {
    classes: PropTypes.object,
    user_role: PropTypes.object
};

export default withStyles(HeaderStyles)(Settings)

