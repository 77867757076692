// Import API Helper
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/apiHelper";

const getBookings = async (params) => {
    const response = await postRequest("bookings/bookinglist", params);
    return response;
}

const getBookingEvent = async (params) => {
    const response = await getRequest(`bookings/${params}`);
    return response;
}

const getBookingEvents = async (params) => {
    const response = await postRequest(`bookings/eventBookings`, params);
    return response;
}

const saveBookingEvent = async (params) => {
    const response = await postRequest("bookings/save", params);
    return response;
}

const previewBookingEvent = async (params) => {
    const response = await postRequest("bookings/preview", params);
    return response;
}

const deleteBookingEvent = async (params) => {
    const response = await deleteRequest(`bookings/${params?.id}`);
    return response;
}

const updateBookingStatus = async (params) => {
    const response = await postRequest(`bookings/updateStatus/${params.id}/${params.status}`, params);
    return response;
}

const getBookingNotification = async ({ event_id }) => {
    const response = await getRequest(`bookings/notification/${event_id}`);
    return response;
}

const saveBookingNotification = async (params) => {
    const response = await postRequest(`bookings/saveNotification`, params);
    return response;
}

// get end user booking details
const getBookingEventDetails = async ({ event_url }) => {
    const response = await getRequest(`bookings/eventDetails/${event_url}`);
    return response;
}

const updateEventStatus=async(changedData)=>{
    const response = await putRequest(`bookings/updateStatus`, changedData);
    return response;
}

const updateCancelStatus=async(changedData)=>{
    const response = await putRequest(`bookings/cancelStatus`, changedData);
    return response;
}

// get booking slots
const getBookingSlots = async (params) => {
    const response = await postRequest(`bookings/getSlots`, params);
    return response;
}

const confirmBooking = async (params) => {
    const response = await postRequest(`bookings/confirm`, params);
    return response;
}

const rescheduleBooking = async (params) => {
    const response = await postRequest(`bookings/reschedule`, params);
    return response;
}

const getBookingDetails = async (params) => {
    const response = await getRequest(`bookings/bookingDetails/${params}`);
    return response;
}

const getBookingsDetails = async (params) => {
    const response = await getRequest(`bookings/bookingsDetails/${params}`);
    return response;
}

const blockUser= async(params)=>{
    const response = await postRequest(`bookings/blockUsers`,params);
    return response;
}

const unBlockUsers= async(params)=>{
    const response = await postRequest(`bookings/unblockUsers`,params);
    return response;
}

const blockedUsersList=async(params)=>{
    const response = await getRequest(`bookings/blockedUsers`);
    return response;
}

const getBookingCount = async (params) => {
    const response = await postRequest("bookings/count", params);
    return response;
}

const getLastBookingAvatar = async (params) => {
    const response = await postRequest("bookings/lastBookingAvatar", params);
    return response;
}

const createGoogleEvent = async (params) => {
    const response = await postRequest("bookings/createGoogleEvent", params);
    return response;
}

const getAvailableDate = async (params) => {
    const response = await postRequest("bookings/available", params);
    return response;
}

// export all services
export const bookingsService = {
    getBookings,
    getBookingEvent,
    saveBookingEvent,
    deleteBookingEvent,
    updateBookingStatus,
    getBookingNotification,
    saveBookingNotification,
    getBookingEventDetails,
    getBookingEvents,
    updateEventStatus,
    getBookingSlots,
    confirmBooking,
    getBookingDetails,
    blockUser,
    unBlockUsers,
    blockedUsersList,
    getBookingCount,
    getLastBookingAvatar,
    getBookingsDetails,
    updateCancelStatus,
    rescheduleBooking,
    createGoogleEvent,
    getAvailableDate,
    previewBookingEvent
};