import {
  Grid,
  IconButton,
  useMediaQuery,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Typography } from "../..";
import {
  AddCircleGradient,
  BlockedUsersIcon,
  ChevronLeft,
  CloseIcon,
  SearchIcon
} from "../../../assets/svg/index";

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DateFilter from "../../../containers/Tasks/DateFilter";
import { CustomTableFilter } from "./CustomTableFilter";
import { CustomTableSort } from "./CustomTableSort";
import Style from "./styles";


/**
 * Custom Table Component
 *
 * @class CustomTable
 * @extends {Component},
 */
function CSTableHeader(props) {
  // Handle TextField Events
  const {
    tableName,
    handleSearch,
    classes,
    enableBtn,
    enableSort,
    enableBackBtn,
    enableSearch,
    handleAdd,
    columnConfig,
    enableFilter,
    handleSort,
    handleTabChange,
    tabValue,
    tabList,
    enableTab,
    handleBack,
    filterData,
    handleFilterChange,
    onFilterSubmit,
    search,
    tableFooterComponent,
    isBooking,
    setBlockUserspopup,
    InputProps,
    dateWidth,
    enableDateFilter,
    enableBlockUserButton,
    filterCount,
    applyFilter,
    dateRange,
    setDaterange,
    openDatePicker,
    setopenDatePicker,
    clearFilter,
    openSearch,
    setOpenSearch
  } = props;
  const [searchKey, setSearchKey] = useState("");
  const [openSearchMobile, setOpenSearchMobile] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const isMobileSmall = useMediaQuery("(max-width:380px)");
  const isMobile = useMediaQuery("(max-width:599px)");
  const isTab = useMediaQuery("(max-width:768px)");

  const isDesktop = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    setSearchKey(search ? search : "");
  }, [search]);

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchKey);
    }
  };

  const handleSearchChange = (event) => {
    const userInput = event.target.value;
    if (userInput === "" && searchKey !== "") {
      handleSearch(userInput);
    }
    setSearchKey(userInput);
  };

  const handleChange = (event, value) => {
    if(value){
      handleTabChange(value);
    }
  };

  const renderTabs = () => {
    return tabList.map((item) => {
      return (
        <ToggleButton value={item.value} aria-label={`${item.label}`}>
          <Typography variant="body2" color="textSecondary">{item.label}</Typography>
          <div class="ToggleBorderBtm"></div>
        </ToggleButton>
      );
    });
  };

  const getHeaderGridSize = (type) => {
    switch (type) {
      case "search":
        if (isMobileSmall) {
          return 7;
        } else if (isMobile) {
          return 8;
        } else if (isTab) {
          return 9;
        }
        return 7;
      case "icons":
        if (isMobileSmall) {
          return 5;
        } else if (isMobile) {
          return 4;
        } else if (isTab) {
          return 3;
        }
        return 5;
      default:
        break;
    }
  };

  const handleCloseSearch = (e)=>{
    setSearchKey("")
    setOpenSearch(!openSearch)
    handleSearch('');
  }

  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="center"
      spacing={1}
    >
      <Grid item className={enableTab && classes.headerContainer} xs={6} md={4}>
        <Grid container alignItems="center" spacing={2} className={`${enableTab && classes.headerContainers} dflex`}>
        <Grid item className="dflex">
          {enableBackBtn && (
            <Grid item className="TableNameCon">
              {!isTab ? (
                <Button
                  className={classes.CSTableBackBtn}
                  variant="contained"
                  onClick={handleBack}
                  title="Back"
                  startIcon={<ChevronLeft />}
                />
              ) : (
                <IconButton
                  className={classes.CSTableBackBtnMob}
                  onClick={handleBack}
                >
                  <ChevronLeft />
                </IconButton>
              )}
            </Grid>
          )}
          {
            !isMobileSmall ? 
                    <Tooltip title = {tableName} arrow>
                      <Grid item className={`${classes.CStableName} TableNameCon`}>
                        <Typography variant={"h2"} className={`fs-20 fw-800 mobileTitle`}>
                            {tableName}
                          </Typography>
                      </Grid>
                    </Tooltip> : 
                    <Tooltip title = {tableName?.length > 12 ? tableName : ''} arrow open={ isMobileSmall && open} onClose={ isMobileSmall && handleClose} onOpen={isMobileSmall && handleOpen}>
                    <Grid item className={`${classes.CStableName} TableNameCon`}>
                      <Typography variant={"h2"} className={`fs-20 fw-800 mobileTitle`}>
                          {tableName?.slice(0,10)}...
                        </Typography>
                    </Grid>
                  </Tooltip>
          }
          </Grid>
          {enableTab && (
            <ToggleButtonGroup
              value={tabValue}
              exclusive
              onChange={handleChange}
              aria-label="text alignment"
              className={`${classes.CSChangeToggle} ${window.innerWidth <= 599  ? "w-100 deleteMe" : 'dflex'}`}
            >
              {renderTabs()}
            </ToggleButtonGroup>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        className={`${classes.CSTableOptions}`}
        // xs={isTab ? 12 : "unset"}
        xs={6} md={8}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          justifyContent="flex-end"
        >
          {enableSearch && (
            <>
              <Grid item className={`${classes.CSTableSearchClickIcon} ${openSearch ? 'show-mobile' : ''}`}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={()=>setOpenSearch(!openSearch)}
                >
                  <SearchIcon width={16} height={16} />
                </IconButton>
              </Grid>

              {openSearch &&
                <Grid
                  // xs={getHeaderGridSize("search")}
                  item
                  className={`${classes.CSTableSearchWrapper} hidden-mobile`}
                >
                  <Typography className={classes.CSTableSearch}>
                    <SearchIcon
                      className={classes.CSTableSearcIcon}
                      onClick={() => handleSearch(searchKey)}
                    />
                    <input
                      className={classes.CSTableSearchInput}
                      placeholder="Search here..."
                      value={searchKey}
                      onInput={handleSearchChange}
                      onKeyDown={handleEnterKey}
                    />
                    <IconButton
                      variant="contained"
                      color="primary"
                      className={classes.CSTableSearchCloseIcon}
                      onClick={(e)=>handleCloseSearch(e)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Typography>
                </Grid>
              }
            </>
          )}
          {enableSort && 
            <Grid item>
              <CustomTableSort
                columnConfig={columnConfig}
                handleSort={handleSort}
              ></CustomTableSort>
            </Grid>
          }
          {enableFilter && (
            <Grid item className={`${classes.filterIcon}`}>
              <CustomTableFilter
                columnConfig={columnConfig}
                handleSort={handleSort}
                filterData={filterData}
                handleFilterChange={handleFilterChange}
                onFilterSubmit={onFilterSubmit}
                InputProps={InputProps}
                filterCount={filterCount}
              />
            </Grid>
          )}
          {enableBtn && (
            <Grid item className={`${classes.CSTableAddBtn}`}>
              <IconButton
                variant="contained"
                color="primary"
                onClick={handleAdd}
              >
                <AddCircleGradient />
              </IconButton>
            </Grid>
          )}


          {
            (enableDateFilter && !isTab) &&
            <Grid item className={classes.dateDisplay}>
              <DateFilter classes={classes} setCallList={true} isBooking={isBooking} dateWidth={dateWidth} applyFilter={applyFilter} dateRange={dateRange}
                setDaterange={setDaterange} openDatePicker={openDatePicker} setopenDatePicker={setopenDatePicker} clearEnabled={true} clearFilter={clearFilter} />
            </Grid>
          }
          {
            (enableBlockUserButton && !isTab) &&
            <Grid item className={classes.dateDisplay}>
              <Button className={`${classes.blockListButton}`} onClick={() => { setBlockUserspopup(true) }}>Blocked User</Button>
            </Grid>
          }
          
        </Grid>
      </Grid>
      {openSearch && isMobile &&
        <Grid item xs={12}>
          {/* <OutsideClickHandler onOutsideClick={(e) => {  handleCloseSearch(e) }}> */}
            <Grid item xs={12} className={`${classes.CSTableSearchWrapper} show-mobile`} >
              <Typography className={classes.CSTableSearch}>
                <SearchIcon
                  className={classes.CSTableSearcIcon}
                  onClick={() => handleSearch(searchKey)}
                />
                <input
                  className={classes.CSTableSearchInput}
                  placeholder="Search here..."
                  value={searchKey}
                  onInput={handleSearchChange}
                  onKeyDown={handleEnterKey}
                />
                <IconButton
                  variant="contained"
                  color="primary"
                  className={classes.CSTableSearchCloseIcon}
                  onClick={(e)=>handleCloseSearch(e)}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>
            </Grid>
          {/* </OutsideClickHandler> */}
        </Grid>
      }
      {
        enableTab && (
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={tabValue}
              exclusive
              onChange={handleChange}
              aria-label="text alignment"
              className={`${classes.CSChangeToggle} ${window.innerWidth <= 599  ? "w-100 dflex" : 'deleteMe'}`}
            >
              {renderTabs()}
            </ToggleButtonGroup>
          </Grid>
        )
      }
      {isDesktop && <Grid className={isMobileSmall ? `${classes.SummaryFooterDesc}` : `${classes.tableFooter}`}>{tableFooterComponent}</Grid>}

      <Grid container  className="justifyBetween smFlex pt5 pb5">
      {
            (enableDateFilter && isTab) &&
            <div>
              <DateFilter classes={classes} setCallList={true} isBooking={isBooking} dateWidth={dateWidth} applyFilter={applyFilter} dateRange={dateRange}
                setDaterange={setDaterange} openDatePicker={openDatePicker} setopenDatePicker={setopenDatePicker} clearEnabled={true} clearFilter={clearFilter} />
            </div>
          }
          {
            (enableBlockUserButton && isTab) &&
            
              <Button className={`${classes.blockListButton}`} onClick={() => { setBlockUserspopup(true) }} style={{height:"33px"}}>Blocked User</Button>
        
          }
      </Grid>
    </Grid>
  );
}

// default props
CSTableHeader.defaultProps = {
  tableName: "Data Table",
  buttonLabel: "New",
  classes: {},
  enableFilter: false,
  enableSearch: false,
  enableBackBtn: false,
  enableSort: true,
  handleAdd: () => { },
  columnConfig: [],
  handleSort: () => { },
  handleSearch: () => { },
  enableBtn: true,
  filterData: {},
  handleFilterChange: () => { },
  onFilterSubmit: () => { },
  handleBack: () => { },
  search: "",
  filterCount: 0
};

CSTableHeader.propTypes = {
  tableName: PropTypes.string,
  classes: PropTypes.object,
  buttonLabel: PropTypes.string,
  handleSort: PropTypes.func,
  handleSearch: PropTypes.func,
  enableBtn: PropTypes.bool,
  enableSort: PropTypes.bool,
  enableSearch: PropTypes.bool,
  enableBackBtn: PropTypes.bool,
  handleAdd: PropTypes.func,
  columnConfig: PropTypes.array,
  enableFilter: PropTypes.bool,
  filterData: PropTypes.object,
  handleFilterChange: PropTypes.func,
  onFilterSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  search: PropTypes.string,
  filterCount: PropTypes.number
};

// Export Component
export const CustomTableHeader = withStyles(Style)(CSTableHeader);
