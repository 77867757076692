import React, { useState, useRef } from 'react';
import {
  Chip,
  Popover,
  withStyles,
  Grid,
  Typography,
  Tooltip,
  useMediaQuery,
  InputAdornment,
  Checkbox,
  IconButton
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ChromePicker } from "react-color";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TextField } from '../TextField';
import { CircleIcon, CircleIcon2, RoundCheckedIcon } from '../../assets/svg';
import ChecIcon from "../../assets/icons/checkIcon.svg"
import UnCheckIcon from "../../assets/icons/unCheckIcon.svg"
import styles from "./styles";
import theme from '../../assets/theme';

const ColorPicker = (props) => {
  const { anchorEl, open,
    onClose, color, onChange
  } = props
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ChromePicker
        color={color}
        onChangeComplete={onChange}
        disableAlpha={true}
      />
    </Popover>
  )
}

const RoundButton = (props) => {
  const { onClick, color, style, ...rest } = props
  return (
    <button
      style={{
        borderStyle: 'solid',
        borderColor: color,
        borderWidth: '2px',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        ...style
      }}
      onClick={onClick}
      {...rest}
    />
  )
}

export const TagsComponent = (props) => {
  const { type = "RoundButton", label, value, options, onChange, placeholder, classes, style = {}, borderRadius, tagProp, IconProps = () => { }, ...rest } = props
  const [tagColor, setTagColor] = useState(null)
  const [selectedTags, setSelectedTags] = useState(value || [])
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const autocompleteRef = useRef(null)

  const onTagColorChange = (color) => {
    const updated_values = selectedTags.map(m => {
      return {
        ...m,
        color: m.value === tagColor?.option ? color.hex : m.color
      };
    });
    setTagColor({
      ...tagColor,
      color: color
    });
    onChange(updated_values);
  };
  const handleChange = (e, v) => {
    const updated_values = (v || []).map(m => getColor(m))
    setSelectedTags(updated_values)
    onChange(updated_values)
  }

  const handleCheckboxChange = (option) => {
    const updatedTags = selectedTags.some(tag => tag.value === option)
      ? selectedTags.filter(tag => tag.value !== option)
      : [...selectedTags, getColor(option)]
    setSelectedTags(updatedTags)
    onChange(updatedTags)
  }

  const getColor = (val) => {
    const d = value.find(f => f.label === val) || options.find(f => f.label === val)
    return d || {
      value: val,
      label: val,
      color: theme.palette.text.blueText
    }
  }
  const sortedOptions = [...options].sort((optionA, optionB) => {
    const isSelectedA = selectedTags.some(tag => tag.value === optionA.label);
    const isSelectedB = selectedTags.some(tag => tag.value === optionB.label);
  
    return isSelectedB - isSelectedA;
  })

  return (
    <div className='heightAutoText tagsInputDiv' style={{ minHeight: '40px' }}>
      <Typography variant="overline" color="textSecondary" className="fLabel">{label}</Typography>
      <form onSubmit={e => { e.preventDefault() }}>
        <Autocomplete
          classes={{option: classes.option}}
          multiple
          id="tags-filled"
          options={(sortedOptions || []).map((option) => option.label)}
          defaultValue={(value || []).map(m => m.label)}
          // for tag not populating when tag comes after render
          value={(value || []).map(m => m.label)}
          freeSolo
          disableCloseOnSelect
          renderTags={(selectedTags, getTagProps) => {

            return (selectedTags || []).map((option, index) => {
              let tagProps = tagProp === "hide" ? null : { ...getTagProps({ index }) };
              return (
                <span
                  key={index}
                  className='tagsChipItem'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #94A3B8',
                    borderRadius: borderRadius ? borderRadius : "24px",
                    paddingLeft: '10px',
                    marginRight: '5px',
                    marginBottom: '5px',
                    ...style,
                  }}>
                  {type === 'RoundButton' ? (
                    <RoundButton
                      className="tagRound"
                      style={{ height: '18px', width: '18px' }}
                      color={getColor(option)?.color}
                      onClick={(e) => {
                        setTagColor({
                          ele: e.target,
                          option
                        });
                      }}
                    />
                  ) : (
                    <RenderOneTag
                      tag={option}
                      tagColor={getColor(option)?.color}
                      onClick={(e) => {
                        setTagColor({
                          ele: e.target,
                          option
                        });
                      }}
                    />
                  )}
                  <Chip
                    variant="outlined"
                    style={{ border: 'none', height: '24px' }}
                    label={type === 'RoundButton' && <span style={{ marginLeft: '-8px' }}>{option}</span>}
                    {...tagProps}
                  />
                  {IconProps(option)}

                </span>
              )
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.tagInputField}
              placeholder={placeholder}
              ref={autocompleteRef}
              // onClick={() => setDropdownOpen(!dropdownOpen)}
              // InputProps={{
              //   ...params.InputProps,
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton>
              //         {dropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
            />
          )}
          renderOption={(option, props) => (
            <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checkedIcon={<img src={ChecIcon} alt="Checked" />}
                icon={<img src={UnCheckIcon} alt="Unchecked" />}
                checked={selectedTags.some(tag => tag.value === option)}
                onChange={() => handleCheckboxChange(option)}
              />
              {type === 'RoundButton' ? (
                <>
                  <RoundButton
                    color={getColor(option)?.color}
                    style={{ marginRight: '8px', pointerEvents: 'none' }}
                    disabled
                  />
                  <Typography variant='body1' color="primary">
                    {option}
                  </Typography>
                </>
              ) : (
                <RenderOneTag
                  tag={option}
                  tagColor={getColor(option)?.color}
                  onClick={(e) => {
                    setTagColor({
                      ele: e.target,
                      option
                    });
                  }}
                />
              )}
            </li>
          )}
          onChange={handleChange}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              // Prevent the default behavior of the Enter key on android
              e.preventDefault();
            }
          }}
          {...rest}
        />
      </form>
      <ColorPicker
        anchorEl={tagColor?.ele}
        open={tagColor !== null}
        onClose={() => setTagColor(null)}
        color={tagColor?.color}
        onChange={onTagColorChange}
      />
    </div>
  );
}
const RenderTagsComponent = (props) => {
  const { classes } = props
  const { tag, tagColor } = props;
    const job_tags = tag?.split(',') || [];
  const job_colors = tagColor?.split(',') || [];
  const maxVisibleTags = 1;
  const isMobile = useMediaQuery('(max-width: 899px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'tags-popover' : undefined;

  return (
    <Grid container className='dflex flexWrap tagsContainer'>
      <Grid item>
        {job_tags.filter(f => f).slice(0, maxVisibleTags).map((tag, tagIndex) => (
          <Grid container key={tagIndex} className='dflex alignCenter'>
            <Grid item className="dflex alignCenter">
              <CircleIcon style={{ fill: job_colors[tagIndex] }} />
            </Grid>
            <Typography color='primary' variant="body1" className="fourLineEllip breakWord tagText">
              {tag}
              {tagIndex < job_tags.length - 1 && tagIndex < maxVisibleTags - 1 ? ',' : ''}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item className='pl-1'>
        {job_tags.length > maxVisibleTags && (
          <Grid item>
            {isMobile ? (
              <>
                <Chip
                  className='br-8 fontSize10'
                  label={`+ ${job_tags.length - maxVisibleTags} more`}
                  variant="outlined"
                  color='secondary'
                  size='small'
                  onClick={handlePopoverOpen}
                />
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  className={classes.renderTag}
                >
                  <Typography sx={{ p: 2 }}>{job_tags.slice(maxVisibleTags).join(', ')}</Typography>
                </Popover>
              </>
            ) : (
              <Tooltip
                title={job_tags.slice(maxVisibleTags).join(', ')}
                arrow
              >
                <Chip
                  className='br-8 fontSize10'
                  label={`+ ${job_tags.length - maxVisibleTags} more`}
                  variant="outlined"
                  color='secondary'
                  size='small'
                />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};


export const RenderOneTag = (props) => {
  const { tag, tagColor, onClick } = props
  const job_tags = tag?.split(',').splice(0, 1) || []
  const job_colors = tagColor?.split(',') || []
  return (
    <Grid className='dflex flexWrap tagsContainer cursorPointer' onClick = {onClick}>
      {
        job_tags.filter(f => f).map((tag, tagIndex) => (
          <>
            {/* <Grid className='dflex alignCenter'>
              <Grid item className="dflex alignCenter">
                <CircleIcon style={{ fill: job_colors[tagIndex] }} />
              </Grid>
              <Typography color='primary' variant="body1" className="fourLineEllip breakWord tagText">
                {tag}
                {tagIndex < job_tags.length - 1 ? ',' : ''}
              </Typography>
            </Grid> */}
            <Grid className='dflex alignCenter'>
              <Grid className='dflex alignCenter justifyCenter' style={{ width: '16px', height: '16px', borderRadius: '2px', marginRight: '8px', background: `${job_colors[0]}20` }}>
                <Grid item style={{ width: '5px', height: '5px', borderRadius: '1px', background: job_colors[0] }}>
                </Grid>
              </Grid>
              <Typography color='primary' variant="body2" className='whiteSpacNowrap fw-600' style={{ color: job_colors[0] }}>
                {job_tags[0]}
              </Typography>
            </Grid>
          </>
        ))
      }
    </Grid>
  )
}

export const StackedBarTags = (props) => {
  const { tagColor } = props
  let job_colors = tagColor?.split(',') || []
  const barHeight = 100 / job_colors.length
  return (
    <Grid
      className='dflex flexWrap flexColumn'
      style={{
        height: '50px', width: '6px', marginRight: '12px'
      }}
    >
      {
        job_colors.map((color) => (
          <>
            <Grid className='dflex alignCenter chipItem' style={{
              width: '6px',
              height: `${barHeight}%`,
              backgroundColor: color || theme.palette.primary.main
            }} />
          </>
        ))
      }
    </Grid>
  )
}

export const Tags = withStyles(styles)(TagsComponent);
export const RenderTags = withStyles(styles)(RenderTagsComponent);
