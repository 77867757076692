export const bookingsConstant = {

    GET_BOOKING_R: "GET_BOOKING_R",
    GET_BOOKING_S: "GET_BOOKING_S",
    GET_BOOKING_F: "GET_BOOKING_F",

    UPDATE_BOOKING_REDUCER: "UPDATE_BOOKING_REDUCER",
    CLEAR_BOOKING_REDUCER: "CLEAR_BOOKING_REDUCER",
    UPDATE_BOOKING_NOTIFICATION_REDUCER: "UPDATE_BOOKING_NOTIFICATION_REDUCER",
    UPDATE_BOOKING_CONFIRMATION_REDUCER: "UPDATE_BOOKING_CONFIRMATION_REDUCER",
    UPDATE_BOOKING_CANCELLATION_REDUCER: "UPDATE_BOOKING_CANCELLATION_REDUCER",
    UPDATE_BOOKING_REMINDER_REDUCER: "UPDATE_BOOKING_REMINDER_REDUCER",
    UPDATE_BOOKING_FOLLOWUP_REDUCER: "UPDATE_BOOKING_FOLLOWUP_REDUCER",

    GET_BOOKING_EVENT_R: "GET_BOOKING_EVENT_R",
    GET_BOOKING_EVENT_S: "GET_BOOKING_EVENT_S",
    GET_BOOKING_EVENT_F: "GET_BOOKING_EVENT_F",
    GET_BOOKING_EVENT_LIST:'GET_BOOKING_EVENT_LIST',
    GET_BOOKING_EVENT_LIST_S:'GET_BOOKING_EVENT_LIST_S',

    SAVE_BOOKING_EVENT_R: "SAVE_BOOKING_EVENT_R",
    SAVE_BOOKING_EVENT_S: "SAVE_BOOKING_EVENT_S",
    SAVE_BOOKING_EVENT_F: "SAVE_BOOKING_EVENT_F",

    DELETE_BOOKING_EVENT_R:"DELETE_BOOKING_EVENT_R",
    DELETE_BOOKING_EVENT_S:"DELETE_BOOKING_EVENT_S",
    DELETE_BOOKING_EVENT_F:"DELETE_BOOKING_EVENT_F",

    UPDATE_BOOKING_EVENT_STATUS_R:"UPDATE_BOOKING_EVENT_STATUS_R",
    UPDATE_BOOKING_EVENT_STATUS_S:"UPDATE_BOOKING_EVENT_STATUS_S",
    UPDATE_BOOKING_EVENT_STATUS_F:"UPDATE_BOOKING_EVENT_STATUS_F",

    GET_BOOKING_NOTIFICATION_R: "GET_BOOKING_NOTIFICATION_R",
    GET_BOOKING_NOTIFICATION_S: "GET_BOOKING_NOTIFICATION_S",
    GET_BOOKING_NOTIFICATION_F: "GET_BOOKING_NOTIFICATION_F",

    SAVE_BOOKING_NOTIFICATION_R: "SAVE_BOOKING_NOTIFICATION_R",
    SAVE_BOOKING_NOTIFICATION_S: "SAVE_BOOKING_NOTIFICATION_S",
    SAVE_BOOKING_NOTIFICATION_F: "SAVE_BOOKING_NOTIFICATION_F",

    UPDATE_QUESTION_INDEX:"UPDATE_QUESTION_INDEX",

    GET_BOOKING_EVENT_DETAILS_R:"GET_BOOKING_EVENT_DETAILS_R",
    GET_BOOKING_EVENT_DETAILS_S:"GET_BOOKING_EVENT_DETAILS_S",
    GET_BOOKING_EVENT_DETAILS_F:"GET_BOOKING_EVENT_DETAILS_F",

    UPDATE_BOOKING_QUESTIONS:"UPDATE_BOOKING_QUESTIONS",

    GET_BOOKING_SLOTS_R:"GET_BOOKING_SLOTS_R",
    GET_BOOKING_SLOTS_S:"GET_BOOKING_SLOTS_S",
    GET_BOOKING_SLOTS_F:"GET_BOOKING_SLOTS_F",

    CONFIRM_BOOKING_R:"CONFIRM_BOOKING_R",
    CONFIRM_BOOKING_S:"CONFIRM_BOOKING_S",
    CONFIRM_BOOKING_F:"CONFIRM_BOOKING_F",

    RESCHEDULE_BOOKING_R:"RESCHEDULE_BOOKING_R",
    RESCHEDULE_BOOKING_S:"RESCHEDULE_BOOKING_S",
    RESCHEDULE_BOOKING_F:"RESCHEDULE_BOOKING_F",

    UPDATE_BOOKING_LIST_FILTER:"UPDATE_BOOKING_LIST_FILTER", 
    GET_BOOKING_EVENT_DATA:'GET_BOOKING_EVENT_DATA',
    BLOCKED_USERS_LIST:'BLOCKED_USERS_LIST',

    GET_BOOKINGS_EVENT:'GET_BOOKINGS_EVENT',
    GET_BOOKINGS_EVENT_R:'GET_BOOKINGS_EVENT_R',

    GET_BOOKING_COUNT_R:"GET_BOOKING_COUNT_R",
    GET_BOOKING_COUNT_S:"GET_BOOKING_COUNT_S",
    GET_BOOKING_COUNT_F:"GET_BOOKING_COUNT_F",

    GET_BOOKING_GEVENT_R:"GET_BOOKING_GEVENT_R",
    GET_BOOKING_GEVENT_S:"GET_BOOKING_GEVENT_S",
    GET_BOOKING_GEVENT_F:"GET_BOOKING_GEVENT_F",

    GET_AVAILABLE_TIME_R: "GET_AVAILABLE_TIME_R",
    GET_AVAILABLE_TIME_S: "GET_AVAILABLE_TIME_S",
    GET_AVAILABLE_TIME_F: "GET_AVAILABLE_TIME_F",

    GET_PREVIEW_EVENT_DETAILS_S: "GET_PREVIEW_EVENT_DETAILS_S"

}