import { isMobile } from "react-device-detect";

const styles = (theme) => ({
    roots: {
        display: "flex",
        flexDirection: "column"
    },
    chatDropZone: {
        // background: "#fff",
        width: "100%",
        height: "100%",
        marginTop: '8%',
        display: "flex",
        flexDirection: "column",
    },
    imageViewContainer: {
        alignSelf: 'center',
        '@media only screen and (max-width: 599px)': {
            alignSelf: 'end'
        },
    },
    previewContainer: {
        // flexDirection: "column",
        width: "100%",
        height: isMobile ? "100%" : "100%",
        background: theme.palette.containers.bg3,
        borderRadius: '0px 0px 12px 12px',
        padding: isMobile ? '16px' : '12px 12px 0px 12px',
        position: 'relative',
        // marginTop:isMobile ? '0px' : '58px',
        '@media only screen and (max-width:599px)':{
            justifyContent:'center !important'
        },
        // flexDirection: 'column-reverse',
        // justifyContent: 'flex-end',
        // paddingTop: '52px'

        // '@media only screen and (min-width: 768px)': {
        //     '&.MuiGrid-root': {
        //         padding: '35px 60px'
        //     }
        // },
        // '@media only screen and (max-width: 768px)': {
        //    flexDirection: 'column-reverse'
        // }
        '& .previewClose': {
            position: isMobile ? 'absolute' : 'unset',
            width: '100%',
            '@media only screen and (max-width: 599px)':{
                marginTop:'20px !important',
                padding:'10px !important'
            }
        },
    },
    previewHeader: {
        width: "100%",
        height: "auto",
        display: "flex",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        lineHeight: "1rem",
        padding: "0.5em",
        borderBottom: "1px solid"
    },
    previewBody: {
        display: "flex",
        // position: "relative",
        width: "calc(100% - 64px)",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: '70%',
        // paddingLeft: '18px',
        // marginTop: '16px',
        width: '100% !important',
        height: 'auto'
        // '@media only screen and (max-width: 768px)': {
        //     width: '100% !important',
        //     height: 'auto'
        // }
    },
    previewBox: {
        width: "100%",
        display: "flex",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        // position: 'absolute',
        //     top: '40px',
        // '@media only screen and (max-width: 768px)': {
        //     // width: '100% !important',
        //     position: 'absolute',
        //     top: '40px'
        // },
        "& .previewImage": {
            position: "relative",
            display: "flex",
            alignItems: 'center',
            maxWidth: '75%',
            maxHeight: '47vh',
            '& div': {
                border: 'none !important',
                maxWidth: '100%'
            },
            "& img": {
                width: "100%",
                height: "100%",
                display: "block",
                // objectFit: "cover",
                // boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
                borderRadius: '11px'
            },
            '& canvas': {
                width: "100%",
                height: "100%",
                display: "block",
                // objectFit: "cover",
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
                borderRadius: '11px',
            },
            '&>div>div': {
                flexDirection: 'column-reverse !important'
            },
            // '@media only screen and (max-width: 768px)': {
            //     '&>div>div': {
            //         flexDirection: 'column-reverse !important'
            //     }
            // },
            '@media only screen and (min-width: 768px)': {
                '& canvas': {
                    height: "100% !important",
                    width: "100% !important",
                    objectFit: "contain !important",
                    maxWidth: '100% !important'
                }
            },
            '& .ci-tools': {
                marginLeft: '20px'
            },
            '& .ciEditor_Screenshot': {
                border: 'none !important'
            }
        }
    },
    bottomContainer: {
        position: "absolute",
        bottom: isMobile ? 'env(safe-area-inset-bottom)' : "1rem",
        width: '100%',
        padding: '8px 16px',
        left: 0,
        right: 0
    },
    uploadBtnMobile: {
        borderRadius: "50%",
        height: '50px',
        width: '50px',
        padding: "0.5rem",
        background: '#2563EB',
        '& .SendIcon2': {
            width: 30,
            height: 30,
            transform: 'rotate(90deg)',
            '& path': {
                fill: '#fff'
            }
        },
    },
    uploadBtn: {
        position: "absolute",
        bottom: "6.2rem",
        right: "1.9rem",
        borderRadius: "50%",
        height: '50px',
        width: '50px',
        padding: "0.5rem",
        background: '#2563EB',
        '& .SendIcon2': {
            width: 30,
            height: 30,
            transform: 'rotate(90deg)',
            '& path': {
                fill: '#fff'
            }
        }
    },
    uploadBtn2: {
        position: "absolute",
        bottom: "1.8rem",
        // right: "2.5rem",
        borderRadius: "50%",
        height: '50px',
        width: '50px',
        // marginLeft:'10px',
        padding: "0.5rem",
        background: '#2563EB',
        '& .SendIcon2': {
            width: 30,
            height: 30,
            transform: 'rotate(90deg)',
            '& path': {
                fill: '#fff'
            }
        }
    },
    previewThumb: {
        display: "flex",
        position: "relative",
        // width: "calc(100% - 55px)",
        // width: "64px",
        // height: "auto",
        // alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        maxHeight: '100%',
        // overflow: 'auto',
        width: '100% !important',
        '@media only screen and (max-width: 768px)': {
            width: '100% !important',
        }
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        alignContent: 'baseline',
        flexWrap: 'nowrap !important',
        overflow: 'auto',


        // '@media only screen and (max-width: 768px)': {
        //     flexWrap: 'nowrap !important'
        // }
    },
    thumb: {
        display: 'inline-flex',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2,
        border: `1px solid ${theme.palette.primary.light}`,
        margin: "0.25rem",
        width: '56px',
        height: '56px',
        // padding: 4,
        boxSizing: 'border-box',
        flexShrink: 0,
        zIndex: "1",
        '&.active': {
            border: '4px solid #2563EB',
            padding: 0,
        },
        '&.addNewFileBtn': {
            flexShrink: 0,
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
            background: theme.palette.containers.bg3,
            right: 0
        },
        '@media only screen and (max-width: 768px)': {
            '&.addNewFileBtn': {
                right: 0
            }
        }
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        height: "100%",
        width: "100%",
        zIndex: "1",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        border: `1px solid ${theme.palette.primary.light}`,
        "& .react-thumbnail-generator": {
            width: "100%",
            height: "100%",
            "& img": {
                width: "90px",
                height: "90px",
                objectFit: "cover",
            }
        }
    },
    thumbImg: {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: "cover",
        zIndex: "1"
    },
    thumbClose: {
        position: "relative",
    },
    thumbCloseBtn: {
        top: "3px",
        right: "3px",
        zIndex: 1,
        position: "absolute",
        background: "#ffffff85",
        padding: "3px",
        '& svg': {
            fontSize: 15
        }
    },
    ci_editor_gbcr: {
        width: '100%',
        height: `${window.innerHeight - 300}px`
    },
    ChatTypingArea: {
        // borderTop: '1px solid #cdcdcd',
        width: '100%',
        position: "relative",
        backgroundColor: theme.palette.primary.bgColor,
        borderRadius: '12px',
        boxShadow: '0px 0px 8px rgb(0 0 0 / 5%)',
        border: `1px solid ${theme.palette.primary.border2}`,
        '@media only screen and (max-width: 899px)': {
            '&>.MuiGrid-container': {
                boxShadow: 'none !important',
                borderRadius: '12px',
                padding: '0px !important'
            },
            '& .chatInputBox': {
                borderRadius: '8px',
                // background: '#F0F0F0'
            },
            '& .textOverallCon': {
                background: 'transparent',
                // borderRadius: '8px'
            },
            '& #chat-msg-editor': {
                justifyContent: 'center',
                // paddingTop: '0px !important',
                // '&:before': {
                //     height: '42px',
                //     padding: '8px 0px'
                // }
            }
        }
    }
});

export default styles;
