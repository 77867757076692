const DeleteDialogStyles = ( theme) => ({
        dialogContent: {
            color: theme.palette.text.text5
        },
        MuiBackdropRoot :{
            backgroundColor: 'rgba(0, 0, 0, 0.6)!important',
            opacity: '1',
            transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        },
        MuiPaperRounded:{
            borderRadius: '12px !important'
            },
        MuiDialogdialogContent:{
                display:"flex",
                flexDirection:'column',
                justifyContent:'center'
            },
        closeButton:{
            padding:'5px 15px !important'
        },
        dialogContentText:{
            maxWidth:'80%',
            textWrap:'wrap',
            overflowWrap:"break-word"
        },
        dialogContentTextForDoc:{
            maxWidth:'100%',
            textWrap:'wrap',
            overflowWrap:"break-word"
        }
    
})

export default DeleteDialogStyles