import { uiConstant } from "../constants/uiConstant";

// Set Initial State
const initialState = {
  loading: false,
  requestPending: 0,
  lazyLoading: false,
  globalLoading: false,
  templateLoading: false,
  googleEventLoading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case uiConstant.LOADING_TRUE:
      return {
        ...state,
        requestPending: state.requestPending + 1,
        loading: true
      };
    case uiConstant.LOADING_FALSE:
      const requestPending = state.requestPending - 1 > 0 ? state.requestPending - 1 : 0;
      return {
        ...state,
        requestPending: requestPending,
        loading: false  //!(requestPending === 0)
      };

    case uiConstant.GLOBAL_LOADING_TRUE:
      return {
        ...state,
        globalLoading: true,
      };
    case uiConstant.GLOBAL_LOADING_FALSE:
      return {
        ...state,
        globalLoading: false,
      };

    case uiConstant.LAZY_LOADING_TRUE:
      return {
        ...state,
        lazyLoading: true,
      };
    case uiConstant.LAZY_LOADING_FALSE:
      return {
        ...state,
        lazyLoading: false,
      };
    case uiConstant.TEMPLATE_LOADING_TRUE:
      return {
        ...state,
        templateLoading: true,
      };
    case uiConstant.TEMPLATE_LOADING_FALSE:
      return {
        ...state,
        templateLoading: false,
      };
    case uiConstant.GOOGLE_EVENTS_LOADING_TRUE:
      return {
        ...state,
        googleEventLoading: true,
      };
    case uiConstant.GOOGLE_EVENTS_LOADING_FALSE:
      return {
        ...state,
        googleEventLoading: false,
      };
    default:
      return state;
  }
}
