import { baseAction } from "./baseAction";
import {pdfService} from "../services/pdfService"
import {pdfConstants} from "../constants/pdfServiceConstant"
import  {loaderConstant} from "../constants/loaderConstant"
import { alertAction } from "./alertAction";
const { request, success, failure } = baseAction;

function createPdf(params, cb = () => {}) {
    return (dispatch) => {
      dispatch(request(pdfConstants.PDF_SERVICE_START_R, ""));
      dispatch(request(loaderConstant.LOADING_TRUE));
  
      pdfService.createPdf(params).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Contract.pdf'); // Set the download filename here
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url); // Clean up the URL object
        link.parentNode.removeChild(link); // Clean up the DOM
  
        dispatch(success(pdfConstants.PDF_SERVICE_START_S));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb(); // callback function on successful download
      }, error => {
        console.error('Error downloading the PDF:', error);
        dispatch(failure(pdfConstants.PDF_SERVICE_START_F, error.response ? error.response.data : "Unknown error"));
        dispatch(request(loaderConstant.LOADING_FALSE));
      });
    };
  }


export const pdfActions = {
    createPdf 
}