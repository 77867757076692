import { baseAction } from "./baseAction";
import { loaderConstant, alertConstant, bookingsConstant } from "../constants";
import { bookingsService } from "../services/bookingService";
import { updateAction } from "./updateAction";

const { request, success, failure } = baseAction;

const getBookings = (params) => {
    return (dispatch) => {
        if (params && params.skip === 0) {
            dispatch(request(bookingsConstant.GET_BOOKING_R, params));
            dispatch(request(loaderConstant.LOADING_TRUE));
        }
        bookingsService.getBookings(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_S, data));
            if (params && params.skip === 0) {
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        }, error => {
            dispatch(failure(bookingsConstant.GET_BOOKING_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const getBookingEvent = (params) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKING_EVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.getBookingEvent(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_EVENT_S, data));
            dispatch(updateAction.update(bookingsConstant.UPDATE_BOOKING_REDUCER, data?.data))
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(bookingsConstant.GET_BOOKING_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const getBookingEvents = (params, callback=()=>{ }) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKING_EVENT_R, params));
        if(!params.appendData){
        dispatch(request(loaderConstant.LOADING_TRUE));
        }
        bookingsService.getBookingEvents(params).then(data => {
            if(!params.appendData){
                dispatch(success(bookingsConstant.GET_BOOKING_EVENT_LIST, data.data));
            } else {
                dispatch(success(bookingsConstant.GET_BOOKING_EVENT_LIST_S, data.data));
                callback()
            }
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(bookingsConstant.GET_BOOKING_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const getLastBookingAvatar = (params) => {
    return (dispatch) => {
        bookingsService.getLastBookingAvatar(params).then(data => {
            const avatar = data?.data;
            if (avatar) {
                dispatch(success(bookingsConstant.UPDATE_BOOKING_REDUCER, { avatar }));
            }
        }, error => { });
    };
}

const saveBookingEvent = (params, cb= ()=>{}) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.SAVE_BOOKING_EVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.saveBookingEvent(params).then(data => {
            dispatch(success(bookingsConstant.SAVE_BOOKING_EVENT_S, data));
            dispatch(updateAction.update(bookingsConstant.UPDATE_BOOKING_REDUCER, { ...(data?.data || {}) }))
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb(data)
        }, error => {
            dispatch(failure(bookingsConstant.SAVE_BOOKING_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const previewBookingEvent = (params, cb = () => { }) => {
    return (dispatch) => {
        bookingsService.previewBookingEvent(params).then(data => {   
            dispatch(success(bookingsConstant.GET_PREVIEW_EVENT_DETAILS_S, data?.data));    
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}
     
const deleteBookingEvent = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.DELETE_BOOKING_EVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.deleteBookingEvent(params).then(data => {
            dispatch(success(bookingsConstant.DELETE_BOOKING_EVENT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb()
        }, error => {
            dispatch(failure(bookingsConstant.DELETE_BOOKING_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const updateBookingStatus = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.UPDATE_BOOKING_EVENT_STATUS_R, params));
        bookingsService.updateBookingStatus(params).then(data => {
            dispatch(success(bookingsConstant.UPDATE_BOOKING_EVENT_STATUS_S, { params, data }));
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
            cb()
        }, error => {
            dispatch(failure(bookingsConstant.UPDATE_BOOKING_EVENT_STATUS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

const getBookingNotification = (params) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKING_NOTIFICATION_R, params));
        bookingsService.getBookingNotification(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_NOTIFICATION_S, data));
        }, error => {
            dispatch(failure(bookingsConstant.GET_BOOKING_NOTIFICATION_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

const saveBookingNotification = (params, cb=()=>{}) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.SAVE_BOOKING_NOTIFICATION_R, params));
        bookingsService.saveBookingNotification(params).then(data => {
            dispatch(success(bookingsConstant.SAVE_BOOKING_NOTIFICATION_S, { params, data }));
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
            cb()
        }, error => {
            dispatch(failure(bookingsConstant.SAVE_BOOKING_NOTIFICATION_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

const getBookingEventDetails = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKING_EVENT_DETAILS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.getBookingEventDetails(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_EVENT_DETAILS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb(data)
        }, error => {
            dispatch(failure(bookingsConstant.GET_BOOKING_EVENT_DETAILS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

const updateEventStatus = (changedData, callback = () => { }) => {
    return (dispatch) => {
        bookingsService.updateEventStatus(changedData).then(data => {
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
            callback()
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

const updateCancelStatus = (changedData, callback = () => { }) => {
    return (dispatch) => {
        bookingsService.updateCancelStatus(changedData).then(data => {
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));

            if(data.message!=='Not Authorized'){
                callback()
            }
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

const getBookingSlots = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKING_SLOTS_R, params));
        bookingsService.getBookingSlots(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_SLOTS_S, data));
            cb(data)
        }, error => {
            dispatch(failure(bookingsConstant.GET_BOOKING_SLOTS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

const confirmBooking = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.CONFIRM_BOOKING_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.confirmBooking(params).then(data => {
            if(data.message=='User is blocked'){
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(request(loaderConstant.LOADING_FALSE));
            } else {
                dispatch(success(bookingsConstant.CONFIRM_BOOKING_S, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                cb(data)
            }
        }, error => {
            dispatch(failure(bookingsConstant.CONFIRM_BOOKING_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

const rescheduleBooking = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.RESCHEDULE_BOOKING_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.rescheduleBooking(params).then(data => {
            if(data.message=='User is blocked'){
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(request(loaderConstant.LOADING_FALSE));
            } else if(data.message=='Not Authorized'){
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(request(loaderConstant.LOADING_FALSE));
            } else {
                dispatch(success(bookingsConstant.RESCHEDULE_BOOKING_S, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                cb(data)
            }
        }, error => {
            dispatch(failure(bookingsConstant.RESCHEDULE_BOOKING_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

const getBookingDetails = (params, callback=()=>{}) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKING_EVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.getBookingDetails(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_EVENT_DATA, data.data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callback(data.data)
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const getBookingsDetails = (params) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKINGS_EVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.getBookingsDetails(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_EVENT_DATA, data.data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const blockUser= (changedData,callback=()=>{}) => {
    return (dispatch) => {
        bookingsService.blockUser(changedData).then(data => {
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
            callback()
        }, error => {
            dispatch(failure(bookingsConstant.SAVE_BOOKING_NOTIFICATION_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}
const unBlockUsers= (changedData,callback) => {
    return (dispatch) => {
        bookingsService.unBlockUsers(changedData).then(data => {
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
            callback()
        }, error => {
            dispatch(failure(bookingsConstant.SAVE_BOOKING_NOTIFICATION_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}


const blockedUsersList= (changedData,callback=()=>{}) => {
    return (dispatch) => {
        bookingsService.blockedUsersList().then(data => {
            dispatch(success(bookingsConstant.BLOCKED_USERS_LIST, data.data));
            callback()
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

const getBookingCount = (params, cb=()=>{}) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKING_COUNT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.getBookingCount(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_COUNT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb(data)
        }, error => {
            dispatch(failure(bookingsConstant.GET_BOOKING_COUNT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

const createGoogleEvent = (params) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_BOOKING_GEVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.createGoogleEvent(params).then(data => {
            dispatch(success(bookingsConstant.GET_BOOKING_GEVENT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(bookingsConstant.GET_BOOKING_GEVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

const getAvailableDate = (params) => {
    return (dispatch) => {
        dispatch(request(bookingsConstant.GET_AVAILABLE_TIME_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        bookingsService.getAvailableDate(params).then(data => {
            dispatch(success(bookingsConstant.GET_AVAILABLE_TIME_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(bookingsConstant.GET_AVAILABLE_TIME_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

// export all booking actions
export const bookingAction = {
    getBookings,
    getBookingEvent,
    saveBookingEvent,
    deleteBookingEvent,
    updateBookingStatus,
    getBookingNotification,
    saveBookingNotification,
    getBookingEventDetails,
    getBookingEvents,
    updateEventStatus,
    getBookingSlots,
    confirmBooking,
    getBookingDetails,
    blockUser,
    unBlockUsers,
    blockedUsersList,
    getBookingCount,
    getLastBookingAvatar,
    getBookingsDetails,
    updateCancelStatus,
    rescheduleBooking,
    createGoogleEvent,
    getAvailableDate,
    previewBookingEvent
};
