 import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/apiHelper";

export const FunnelService = {
    createFunnel,
    appendSteps,
    getFunnelTemplateById,
    getFunnelTemplateSteps,
    getFunnelTemplateList,
    getProposalsTemplates,
    reorderFunnelSteps,
    getPublicInvoiceList,
    updateFunnelStepTemplate,
    createFunnelSpace,
    getFunnelSpaceById,
    updateFunnelSpace,
    deleteFunnelSpace,
    getContractTemplates,
    createProposalTemplates,
    createContractTemplates,
    deleteFunnel,
    createProposal,
    updateProposal,
    getProposal,
    getContract,
    createContract,
    updateContract,
    getFunnelInstance,
    updateFunnelInstance,
    createFunnelInstance,
    getContractInstance,
    getProposalInstance,
    updateContractInstance,
    updateProposalInstance,
    createContractInstance,
    createProposalInstance,
    getFilterData,
    getSignedContracts,
    getContractUsername,
    updateFunnelTemplate,
    createPage,
    updatePage,
    getPage,
    createForm,
    updateForm,
    getForm,
    createVideo,
    updateVideo,
    getVideo,
    createBooking,
    updateBooking,
    getBooking,
    getNewtemplates,
    contractSigned,
    getSignedContractByUUID,
    formSubmitted
};

async function contractSigned(data){
  return await postRequest(`zapier/contract-signed`, data);
}

async function formSubmitted(data){
  return await postRequest(`zapier/form-submitted`, data);
}

async function createFunnel(data) {
  return await postRequest(`funnel/template/`, data);
}
async function appendSteps(data) {
  return await postRequest(`funnel/append-steps/`, data);
}

async function getFunnelTemplateSteps(params,endUser=false) {
  return await getRequest(`funnel/template_steps/${params}?endUser=${endUser}`);
}

async function getFunnelTemplateById(id) {
  return await getRequest(`funnel/template/${id}`);
}
async function getFunnelTemplateList(body) {
  return await postRequest(`funnel/template/list`, body);
}

async function getProposalsTemplates() {
  return await getRequest(`proposal_template`);
}
async function getContractTemplates() {
  return await getRequest(`contract_templates`);
}

async function createProposalTemplates(body) {
  return await postRequest(`proposal_template`, body);
}

async function createContractTemplates(body) {
  return await postRequest(`contract_templates`, body);
}

async function reorderFunnelSteps(body) {
  return await postRequest(`funnel/template_steps/reorder`, body);
}

async function getPublicInvoiceList(body) {
  return await postRequest(`invoice/getPublicInvoiceList`, body);
}
async function deleteFunnel(id) {
  return await deleteRequest(`funnel/template/${id}`);
}
async function createProposal(body) {
  return await postRequest(`proposal`, body);
}
async function updateProposal(id, body) {
  return await putRequest(`proposal/${id}`, body);
}
async function getProposal(id) {
  return await getRequest(`proposal/${id}`);
}
async function createContract(body) {
  return await postRequest(`contracts`, body);
}
async function updateContract(id, body) {
  return await putRequest(`contracts/${id}`, body);
}
async function getContract(id) {
  return await getRequest(`contracts/${id}`);
}
async function createPage(body) {
  return await postRequest(`funnel_page`, body);
}
async function updatePage(id, body) {
  return await putRequest(`funnel_page/${id}`, body);
}
async function getPage(id) {
  return await getRequest(`funnel_page/${id}`);
}
async function createForm(body) {
  return await postRequest(`funnel_form`, body);
}
async function updateForm(id, body) {
  return await putRequest(`funnel_form/${id}`, body);
}
async function getForm(id) {
  return await getRequest(`funnel_form/${id}`);
}
async function createVideo(body) {
  return await postRequest(`funnel_video`, body);
}
async function updateVideo(id, body) {
  return await putRequest(`funnel_video/${id}`, body);
}
async function getVideo(id) {
  return await getRequest(`funnel_video/${id}`);
}
async function createBooking(body) {
  return await postRequest(`funnel_booking`, body);
}
async function updateBooking(id, body) {
  return await putRequest(`funnel_booking/${id}`, body);
}
async function getBooking(id) {
  return await getRequest(`funnel_booking/${id}`);
}

async function updateFunnelStepTemplate(params,body){
    return await putRequest(`funnel/step_template/${params}`, body);
}
async function createFunnelSpace(data) {
    return await postRequest(`funnel_spaces`, data);
}

async function getFunnelSpaceById(params) {
    return await getRequest(`funnel_spaces/space/${params}`);
}

async function updateFunnelSpace(params, body) {
    return await putRequest(`funnel_spaces/space/${params}`, body);
}

async function deleteFunnelSpace(params) {
    return await putRequest(`funnel_spaces/space/delete/${params}`);
}

async function getFunnelInstance(instanceId) {
  return await getRequest(`funnel-instance/${instanceId}`);
}
async function updateFunnelInstance(instanceId,data,mode = "",) {

  return await putRequest(`funnel-instance/${instanceId}?mode=${mode}`,data);
}
async function createFunnelInstance(data) {
  return await postRequest(`funnel-instance`,data);
}

async function getContractInstance(funnelId,instanceId,templateId) {
  return await getRequest(`funnel-instance/${instanceId}/contract?template_id=${templateId}&funnel_id=${funnelId}`);
}
async function getProposalInstance(funnelId,instanceId,templateId) {
  return await getRequest(`funnel-instance/${instanceId}/proposal?template_id=${templateId}&funnel_id=${funnelId}`);
}
async function createContractInstance(funnelId,instanceId,data) {
  return await postRequest(`funnel-instance/${instanceId}/contract?funnel_id=${funnelId}`,data);
}
async function createProposalInstance(funnelId,instanceId,data) {
  return await postRequest(`funnel-instance/${instanceId}/proposal?funnel_id=${funnelId}`,data);
}
 
 
async function updateContractInstance(contractInstanceId,data) {
  return await putRequest(`funnel-instance/contract/${contractInstanceId}`,data);
}
async function updateProposalInstance(proposalInstanceId,data) {
  return await putRequest(`funnel-instance/proposal/${proposalInstanceId}`,data);
}

async function getFilterData () {
  const response = await getRequest(`funnel/filter`);
  return response;
}
async function getSignedContracts(params){
  const response = await postRequest(`funnel-instance/contract/signed?funnel_id=${params.id}`,params);
  return response;
}

async function getContractUsername (id) {
 const response = await getRequest(`funnel-instance/filters?funnel_id=${id}`);
 return response;
}
async function updateFunnelTemplate(templateId, data) {
  return await putRequest(`funnel/template/${templateId}`, data);
}

async function getNewtemplates (params){
  return await getRequest(`funnel/funnelsteps?category=${params.category}`)
}

async function getSignedContractByUUID(params){
  return await getRequest(`funnel-instance/${params}/signed_contract`)
}