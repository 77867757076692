const styles = (theme) => ({
    autocomplete: {
        '.MuiAutocomplete-root': {
            height: '56px'
        }
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: '#ffff',
      },
    },
    tagInputField: {
        "& input": {
            minHeight: "3rem",
            // '@media only screen and (max-width: 599px)': {
            //     width:'100% !important'
            // }
    },
    '& .MuiAutocomplete-input': {
      padding: '4.5px 10px !important'

    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '11px !important'
    }
    },
  renderTag: {
    '@media only screen and (max-width: 599px)': {
      '& .MuiPopover-paper': {
        padding: '10px !important',
        maxHeight: '100% !important',
        maxWidth: '100 !important',
        overflowY: 'auto !important',
        }
    }
    }
});

export default styles;
