const funnelStyles = (theme) => ({
  CreateFnlBtn: {
    padding: "12px 30px",
    fontWeight: 700,
    borderRadius: "26px !important",
    background: "linear-gradient(90.6deg, #3364EF 15.52%, #8461FE 91.04%)",
    color: "white",
    textTransform: "uppercase"
  },
  sideBarWrapper: {
    zIndex: 2,
    height: "100%",
  },
  backButton: {
    // background: theme.palette.containers.card,
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 400,
    padding: "8px 12px"
  },
  canvasWrapper: {
    position: "relative",
    zIndex: 1,
  },
  pageTitle: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  pageSubTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  funnelToolbar: {
    // position: "sticky",
    margin: 0,
    padding: 0,
    // top: 0,
    zIndex: 5,
    width: "100%",
    color: theme.palette.primary.main,
    background: theme.palette.containers.card,
    boxShadow: "0px 4px 4px 2px #0000000F",
  },
  sideBar: {
    height: '100%',
    overflowY: "scroll",
    padding: '20px 30px',
    color: theme.palette.primary.main,
    borderRadius: '7px',
    background: theme.palette.containers.card,
    "& ::-webkit-scrollbar": {
      display: "none"
    }
  },
  canvas: {
    padding: "20px 30px",
    height: "100%",
    borderRadius: "7px",
    background: theme.palette.containers.card,
    position: "relative",
    overflowY: "auto",
    "& .MuiDialogActions-root.p16.MuiDialogActions-spacing": {
      position: 'sticky',
      bottom: '0',
      zIndex: '16',
      // borderRadius: '12px',
      boxShadow: '2px 2px 20px 2px #00000022',
      background: theme.palette.containers.card,
      '@media only screen and (max-width: 599px)': {
        bottom: '0px !important',
        // paddingBottom: "30px !important"
      }
    },
    paddingRight: "0",
    '& .invoice_funnel_items': {
      marginLeft: "0px !important"
    },

    '& .funnel_inv_curreny_text_con': {
      paddingLeft: "0px !important"
    },
    '& .funnel_inv_due_con': {
      paddingLeft: "0px !important"
    },
    '& .funnelInvoiceWhiteCon': {
      paddingRight: "10px !important",
      paddingLeft: "20px !important",
    },
    '& .funnel_invoice_autopay_sub_con': {
      display: "flex",
      gap: "10px"
    },
    '& .funnel_remove_container': {
      paddingLeft: "0px !important"

    },
    '@media only screen and (max-width: 599px)': {
      height: 'calc(100% - 50px)!important'
    }
    // '& .funnel_invoice_payment_con': {
    //   paddingLeft: "25px !important"
    // },

    // '& .funnelInvoiceContainer': {
    //   paddingLeft: "0px !important"
    // },

  },

  container: {
    margin: "5px 0 0 0",
    height: "calc(100% - 55px)",
    width: "100%",
  },
  mobileContainer: {
    display: "none",
    height: "100%"
  },
  SlideInMenuShow: {
    animation: `$slideIn 1s ease-in-out`,
    position: "absolute",
    boxShadow: "15px 0px 20px 10px #0000000F",
    top: "0px",
    left: "0",
    height: "100%",
    background: theme.palette.containers.card,
    width: "80%",
    borderRadius: "8px",
    zIndex: 1001,
    overflowY: "scroll",
  },
  SlideInMenuHide: {
    transform: "translateX(-200%)",
    transition: "1.2s",
    position: "absolute",
    boxShadow: "15px 0px 20px 10px #0000000F",
    top: "0px",
    left: "0",
    height: "100%",
    background: theme.palette.containers.card,
    width: "80%",
    borderRadius: "8px",
    zIndex: 1001,
    overflowY: "scroll",
  },
  "@keyframes slideIn": {
    "0%": {
      transform: "translateX(-200%)",
    },
    "100%": {
      transform: "translateX(0)",
    },
  },
  templatePickerCloseButton: {
    fontSize: "9px",
    padding: "8px",
    width: "26px",
    borderRadius: "8px",
    height: "26px",
    background: theme.palette.disabled.disabled1.bg,
  },
  menuButton: {
    border: `1px solid ${theme.palette.primary.border1}`,
    borderRadius: "8px",
    padding: "6px 8px",
    color: theme.palette.text.secondary,
    background: theme.palette.containers.bg4,
  },
  cancelButton: {
    fontSize: "14px",
    borderRadius: "8px",
    border: "1px solid #D8DDE1",
    color: theme.palette.primary.main,
    background: theme.palette.containers.card,
    fontWeight: 500,
    padding: "8px 24px"
  },
  CopyLinkBtn: {
    fontSize: "14px",
    borderRadius: "8px",
    border: "1px solid #2563EB",
    color: "#2563EB",
    fontWeight: 500,
    padding: '7px 20px',
    // maxWidth:"200px"
  },
  cancelBtn: {
    fontSize: "14px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.type === "dark" ? "#BBC4CF33" : "#D8DDE1"}`,
    background: theme.palette.containers.bg4,
    color: theme.palette.text.secondary,
    fontWeight: 500,
    padding: '7px 20px',

    "&.MuiButton-root:hover": {
      background: theme.palette.containers.bg6,
    }
  },
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "#F3FAFA",
  },
  dialogCloseBtn: {
    borderRadius: "7px",
    border: "1px solid #2563EB",
    color: "#2563EB",
  },
  "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #eee",
  },
  cardOutline: {
    borderRadius: "10px",
    border: `1px solid ${theme.palette.primary.border8}`,
    padding: "8px",
  },
  cardBg: {
    background: `${theme.palette.containers.bg41}`,
    padding: "15px",
    borderRadius: "10px",
    border: "1px solid transparent",
    "& .selectBtn": {
      background: theme.palette.containers.bg45,
      color: theme.palette.text.text9,
      fontWeight: "600",
      fontSize: "14px",
      borderRadius: "20px",
      boxShadow: "4px 3px 8px 0px rgba(37, 99, 235, 0.3)",
    },
  },
  invoiceLists: {
    // padding:'5px',
    "& .MuiListItem-root": {
      // background:'#2563EB',
      margin: "5px",
      "&:hover": {
        background: theme.palette.containers.body,
        cursor: "pointer",
        borderRadius: "7px",
      },
    },
    "& .MuiListItemText-primary": {
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  endUserWrapper: {
    background: "white",
    height: "100vh",
    width: "100vw",
  },
  funnelSaveButton: {
    color: '#fff',
    background: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
    borderRadius: '7px',
    fontSize: "14px",
    fontWeight: 600,
    padding: '8px 36px'
  },
  saveTemplateButton: {
    fontSize: "14px",
    fontWeight: "500",
    padding: "8px 12px",
    borderRadius: "8px"
  },
  stepAddButton: {
    background: "var(--Primary-600-base, #2563EB)",
    boxShadow: "4px 8px 18px 0px rgba(37, 99, 235, 0.30)",
    padding: "10px 14px",
    borderRadius: "1000px",
    color: "white",
    fontWeight: 500,
    fontSize: "14px",
    "&:hover": {
      background: "var(--Primary-600-base, #2563EB)",
    }
  },
  FunnelLanding: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& .showOnRowHover': {
      "& .MuiButton-containedSecondary": {
        backgroundColor: "#EDEDED !important",
      }
    },
    '& .CSTableContainer': {
      "@media only screen and (min-width: 900px)": {
        '& .CSTableWrapper': {
          height: 'calc(100% - 64px)'
        }
      }
    },
    '& .CTHeaderWrapper': {
      '@media only screen and (max-width: 899px)': {
        position: 'sticky',
        top: 0,
        background: theme.palette.containers.body
      }
    },
    '@media only screen and (min-width:900px) and (max-width: 1499px)': {
      height: '100%'
    }
  },
  FunnelLandingContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&--hidden": {
      display: 'none'
    }
  },
  FunnelPopupTitle: {
    fontFamily: "Inter",
    fontWeight: 700,
    textAlign: "center",
    lineHeight: "22px",
    letterSpacing: "0.2px",
    textAlign: "left",
    padding: "20px 30px"
  },
  FunnelDescription: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0px",
    textAlign: "center",
    padding: "15px 0px",
    width: "500px",
    color: "#868789"
  },
  FunnelStepBoxContainer: {
    display: "flex",

    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "20px",
      right: "20px",
      backgroundColor: theme.palette.colors.blue,
      color: "#ffffff"
    }
  },
  FunnelStepBox: {
    width: "138px",
    height: "117px",
    borderRadius: "14px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.containers.bg12}`,
    cursor: "pointer",
    "&--selected": {
      boxShadow: "0px 4px 25px 0px #00000040",
      border: `1px solid ${theme.palette.colors.blue}`
    }

  },
  FunnelStepIcon: {
    width: "42px",
    height: "42px",
    top: "21px",
    left: "48px",
    borderRadius: "12px",
    border: "1px",
    marginBottom: "8px"
  },
  FunnelPopupContent: {
    overflow: "hidden",
    color: theme.palette.primary.main,

  },
  FunnelStepLabel: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "30px",
    letterSpacing: "-0.02em",
    textAlign: "center"
  },
  FunnelNameInput: {
    margin: "0px 12px"
  },
  PopoverBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    gap: "14px",
    padding: "0px 30px 36px 30px"
  },
  CancelBtn: {
    marginRight: "12px"
  },
  FunnelPopupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: "20px 30px"
  },
  FunnelPopupSection: {
    padding: "0px 30px 6px 30px"
  },
  FunnelDivider: {
    marginBottom: "12px"
  },
  FunnelDialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "695px",
      // minWidth: "695px"
    }
  },
  StepList: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '24px 24px',
    marginTop: '20px'
  },
  stepDisabled: {
    opacity: 0.5,
  },
  viewOnboardingBtn: {
    border: `1px solid ${theme.palette.type === "dark" ? "#A2B0B933" : "#A2B0B9"}`,
    fontSize: '12px',
    background: theme.palette.containers.bg46,
    fontWeight: '500',
    padding: "6px 12px",
    borderRadius: '8px',
    color: theme.palette.primary.main,
  },
  AvatarContainer: {
    "& .MuiAvatar-root": {
      height: "32px",
      width: "32px",
      fontSize: '14px'
    }
  },
  funnelName: {
    width: "350px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  templateItemConainer: {
    padding: "10px",
    border: "1px solid #aaa",
    borderRadius: "8px",
    width: 'max-content',
    position: "relative",
    "&:hover": {
      border: "1px solid #2563EB",
      boxShadow: "0px 4px 15px 0px #2563EB66",

    }
  },
  blankPageButton: {
    letterSpacing: '0px',
    fontSize: '14px',
    background: "#2563EB",
    color: "#fff",
    borderRadius: '8px',
    padding: '8px 14px',
    marginRight: '10px',
    fontWeight: "600",
    "&:hover": {
      background: "#2563EB",
    }
  },
  CompletedPopupPaper: {
    padding: "40px 54px",
    borderRadius: "18px",
    width: "752px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden"
  },
  saveAsPopup: {
    borderRadius: "10px",
    boxShadow: "0px 4px 55px 55px rgba(0, 0, 0, 0.06)",
    padding: "20px",
    width: "400px",
    color: theme.palette.primary.main,
    background: theme.palette.containers.card,
  },
  completedHeader: {
    fontWeight: "700",
    fontSize: "22px",
    color: theme.palette.primary.main,
  },
  userListPopoverPaper: {
    padding: "10px",
    maxHeight: "400px",
    minWidth: "300px",
    overflowY: "scroll",
    border: `1px solid ${theme.palette.primary.border1}`,
    borderRadius: "18px",
    color: theme.palette.primary.main,
    background: theme.palette.containers.card,
  },
  PreviewHeader: {
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.primary.main,
  },
  funnelContainer: {
    height: "80%",
  },
  sortCardContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  sortBackdrop: {
    position: "fixed",
    background: "rgb(0 0 0 / 39%)",
    height: "100%",
    top: "0",
    width: "100%",
    left: "0",
    zIndex: "`1000",
    animation: `$slideEffect 0.5s ease-in-out`,
  },
  sortCard: {
    zIndex: "1001",
    position: "fixed",
    bottom: "0px",
    padding: "10px 0",
    background: theme.palette.containers.card,
    left: "0px",
    borderRadius: "18px 18px 0px 0px",
    width: "100%",
    height: "max-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "left",
    animation: `$slide 0.5s ease-in-out`,
  },
  sortTabCard: {
    zIndex: "10",
    position: "fixed",
    top: "55px",
    width: "30%",
    padding: "10px 0",
    background: theme.palette.containers.card,
    right: "0px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    animation: `$tabSlide 0.5s ease-in-out`,
    boxShadow: "0px 4px 55px 55px #0000000F",

  },
  "@keyframes tabSlide": {
    "0%": {
      opacity: "1",
      right: "-100%",
    },
    "100%": {
      opacity: 1,
      right: "0",
    },
  },

  "@keyframes slide": {
    "0%": {
      opacity: "1",
      bottom: "-100%",
    },
    "100%": {
      opacity: 1,
      bottom: "0",
    },
  },
  "@keyframes slideEffect": {
    "0%": {
      opacity: "0",
      bottom: "-100px",
    },
    "100%": {
      opacity: 1,
      bottom: "0",
    },
  },
  contractUserList: {
    overflowY: "scroll",
    flexDirection: "row"
  },
  SlideInMenuHeader: {
    marginBottom: "20px",
  },
  blankPageButtonMobile: {
    height: 'min-content',
    color: 'white',
    borderRadius: '5px',
    background: "#2563EB",
    lineHeight: '0'
  },
  FunnelPreviewHeaderContainer: {
    color: theme.palette.primary.main,
    background: theme.palette.containers.card,
  },
  // FunnelNameInputCon:{
  //   "& .MuiOutlinedInput-inputMarginDense":{
  //     color:theme.palette.primary.bodyBgColor,
  //   }
  // },
  previewBtn: {
    background: theme.palette.containers.bg45,
    color: theme.palette.text.text9,

    '&.MuiButton-root:hover': {
      background: theme.palette.containers.bg45
    },
  },

  CopyBtn: {
    background: theme.palette.containers.arrowBg,
    color: theme.palette.text.text9,

    '&.MuiButton-root:hover': {
      background: theme.palette.containers.arrowBg,
    }
  },
  invoiceAmntList: {
    color: theme.palette.text.text9
  },
  FunnelTitle: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "30px",
    textAlign: "center"
  },
  funnelNodataContainer: {
    marginLeft: "50px",
    marginTop: "6px"
  },
  FunnelNoDataTitle: {
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center"
  },
  FunnelNodataDescription: {
    color: "#868789",
    fontSize: "14px",
    fontWeight: 400,
    padding: "20px 0px",
    textAlign: "center"
  },
  FunnelNoDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "&--hidden": {
      display: 'none'
    }
  },
  FunnelSignedContracts: {
    position: "relative"
  },
  NoDataHeader: {
    position: "absolute",
    top: "20px",
    left: "30px",
    display: "flex",
    alignItems: "center",
    gap: "20px"
  },
  NoDataBtn: {
    background: "#F8FAFC",
    gap: "10px",
    color: "#64748B",
    borderRadius: "8px",
    padding: "10px 15px",
    fontWeight: 600,
    fontSize: "14px"
  },
  NoDataTitle: {
    fontSize: "20px",
    fontWeight: 700
  },
  NodataIcon: {
    display: "flex",
    alignItems: "center",
    marginLeft: "60px"
  },
  funnelPopupCloseIcon: {
    padding: "20px 30px"
  },
  //  FunnelPopupTitle:{
  //   padding:"20px 30px"
  // },
  FunnelNameInputCon: {
    padding: "0 30px"
  },
AddFBtn:{
 "&.MuiButton-contained.Mui-disabled": {
  background: theme.palette.type === "dark" ? "#33373E !important" : "#E0E0E0 !important",
  color:"#94A3B8 !important",
 }
},
preview_contract_container:{
  display:"flex",
  color: theme.palette.primary.main,
  background: theme.palette.containers.card,
  height:"100vh",
  paddingBottom: 'env(safe-area-inset-bottom)',
},
download_container:{
    height:"100vh",
    overflowY:"scroll",
    paddingBottom:"50px",
},
download_btn:{
  border:"2px solid #EAE9E9",
  padding:"10px 20px",
  borderRadius:"10px",
  display:"flex",
  justifyContent:"center",
  gap:"10px",
  cursor:"pointer"
},
signedContractName:{
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
},
  '@media only screen and (max-width:899px)': {
    SlideInMenuHeader: {
      marginBottom: "0"
    },
    SlideInMenuShow: {
      width: "100%",
      boxShadow: "none"
    },
    // funnelContainer:{
    //   height:"0%"
    // },
    PopupListContainer: {
      width: "100%"
    },
    funnelInvoicePopover: {
      '&.MuiPopover-paper': {
        left: "190px",
        marginRight: "20px",
      }
    },
    sideBarWrapper: {
      flexGrow: "0",
      maxWidth: "30%",
      flexBasis: "30%"
    },
    canvasWrapper: {
      flexGrow: "0",
      maxWidth: "70%",
      flexBasis: "70%"
    },
    sideBar: {
      padding: "20px 9px"
    },
    funnelPopover: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    funnelPopoverTitle: {
      textAlign: "center",
      flexDirection: "column"
    },
    canvas: {
      padding: "20px 0",
      // height:"100vh",
      overflowX: "hidden",
      "& .funnelInvoiceContainer": {
        flexGrow: "0",
        maxWidth: "100%",
        flexBasis: "100%",
        padding: "9px",
        paddingLeft: "0px !important"
      },
      "& .funnelInvSubTotal": {
        flexGrow: "0",
        maxWidth: "100%",
        flexBasis: "100%",
        padding: "2px !important"
      },

      '& .funnelInvoiceRight': {
        justifyContent: "flex-start !important"
      },
      '& .funnelInvPayOptions': {
        marginTop: "10px",
        paddingLeft: "0px !important"
      },
      '& .funnelInvDisContainer': {
        marginLeft: "0px !important"
      },
      '& .funnelSpaceAlign': {
        gap: "20px"
      },
      '& .funnelInvItems': {
        margin: "10px 0 !important"
      },
      // "& .MuiDialogActions-root.p16.MuiDialogActions-spacing":{
      //   bottom:"50px"
      // },
      '&.funnelFullPageCon': {
        position: "static !important",
      },

      '& .funnelTemplateCon': {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%"
      },
      '& .funnel_email_template': {
        gap: "10px",
      },
      '& .funnel_temp_con': {
        width: '180px',
        height: '266px',

      },
      '& .funnel_invoice_payment_con': {
        display: "flex",
        flexDirection: "column",

      },
      '& .funnel_invoice_autopay_sub_con': {
        paddingLeft: "0px !important",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "flex-start",
        gap: "10px"

      },
      '& .funnel_remove_container': {
        paddingTop: "0px !important"

      },
      '& .invoice_funnel_items': {
        marginLeft: "0px !important"
      },
      '& .funnel_invoice_curreny_tab': {
        paddingLeft: "0px !important"
      },
      '& .funnel_inv_curreny_text_con': {
        paddingLeft: "0px !important"
      },
      '& .funnel_inv_due_con': {
        paddingLeft: "0px !important"
      },
      '& .funnel_invoice_notes': {
        paddingLeft: "0px !important"
      },
      "& .funnel_invoice_notes_con": {
        padding: "10px"
      },


    },
    StepList: {
      justifyContent: "flex-start"
    },
    FunnelPopupContent: {
      overflowY: "scroll"
    },
    cardBg: {
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "9px"
    },
    funnelSaveButton: {
      padding: "6px 20px!important"
    },
    CopyLinkBtn: {
      padding: "6px 8px"
    },
    OnboardingButtonGroup: {
      justifyContent: "center"
    },
    FunnelTableContainer: {
      overflowY: "hidden"
    }
  },

  '@media only screen and (max-width:599px)': {
    mobileContainer: {
      display: "block"
    },
    MobilDeActionsContainer: {
      position: "fixed",
      bottom: 0,
      right: 0,
      width: "100vw",
      zIndex: 1000,
      background: theme.palette.containers.card,
    },
    container: {
      display: "none"
    },
    OnboardingButtonGroup: {
      gap: "6px",
      justifyContent: "space-between"
    },
    CopyLinkBtn: {
      padding: "0px 8px",
      fontSize: "12px",
    },
    funnelSaveButton: {
      padding: "7px 24px !important",
      fontSize: "12px"
    },
    FunnelDialog: {
      "& .MuiDialog-paperWidthSm": {
        margin: "2px"
      }
    },
    StepList: {
      justifyContent: "center"
    },
    FunnelPopupSection: {
      padding: "0px 20px 36px 20px"
      // padding:"0px"
    },
    mobileFunnelIcon: {
      display: "flex",
      gap: "8px"
    },
    PreviewBtn: {
      '& .MuiButton-label': {
        padding: "0 8px",
        fontSize: "12px"
      }
    },
    completedHeader: {
      fontSize: "18px"
    },
    CompletedPopupPaper: {
      margin: "15px",
      padding: "40px"
    },
    canvas: {
      "& .MuiDialogActions-root.p16.MuiDialogActions-spacing": {
        bottom: 'calc(env(safe-area-inset-bottom) + 50px)',
        padding: '10px',
        bottom: '40px'
      },
      '&.funnelFullPageCon': {
        position: "static !important",
      },

      '& .funnelTemplateCon': {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%"
      },
      '& .funnel_email_template': {
        gap: "8px !important",
      },
      '& .funnel_temp_con': {
        width: '150px',
        height: '238px',
      },
      '& .funnel_invoice_autopay_sub_con': {
        paddingLeft: "0px !important",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "flex-start",
        gap: "10px"
      },
      '& .funnel_remove_container': {
        paddingTop: "0px !important"
      },
      '& .funnelInvoiceTableCon': {
        paddingRight: "11px !important",
        padding: "0px !important"
      },
      '& .invoice_funnel_items': {
        marginLeft: "0px !important"
      },
      '& .funnel_invoice_quantity': {
        paddingBottom: "35px !important"
      },
      "& .funnelInvoiceContainer": {
        flexGrow: "0",
        maxWidth: "100%",
        flexBasis: "100%",
        padding: "0px"
      },
      "& .funnel_inv_curreny_text_con": {
        paddingLeft: "0px !important"
      },
      "& .funnel_invoice_notes_con": {
        padding: "10px"
      },
      "& .funnel_invoice_notes_header": {
        padding: "5px"
      },
      "& .funnel_invoice_logo": {
        padding: "5px",
        marginLeft: "5px"
      },


    },
    sideBar: {
      padding: "20px 12px"
    },
    FunnelSignedContracts: {
      "& .mobileTitle": {
        fontSize: "16px !important",
      },
      "& .TableNameCon": {
        padding: "2px !important"
      }
    },
    FunnelPopupHeader: {
      padding: "0px",
      position: "sticky",
      top: "0",
      zIndex: "2",
      background: theme.palette.containers.card,
    },
    FunnelNameInputCon: {
      padding: "0px 20px 6px 20px"
    },
    PopoverBtnContainer: {
      position: "sticky",
      bottom: "0",
      zIndex: "2",
      background: theme.palette.containers.card,
      padding: "14px",
      margin: "0"
    },
    CreateFnlBtn: {
      padding: "12px 20px",
      fontSize: "12px"
    },
    FunnelTitle: {
      fontSize: "16px"
    },
    FunnelDescription: {
      fontSize: "12px",
      lineHeight: "19.2px",
      maxWidth: "256px"
    },
    FunnelNodataIcon: {
      textAlign: "center",
      "& svg": {
        width: "230px",
        height: "96px"
      }
    },
    FunnelStepBoxContainer: {
      "& .MuiGrid-root:hover": {
        backgroundColor: "transparent"
      }
    },
    NoDataHeader: {
      left: "18px",
      gap: "8px"
    },
    NoDataBtn: {
      padding: "8px 10px"
    },
    NoDataTitle: {
      fontSize: "16px",
    },
    FunnelNoDataTitle: {
      fontSize: "18px"
    },
    contractDesc:{
     fontSize:"12px !important"
    },
  },
});
export default funnelStyles;
