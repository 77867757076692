import React from "react";
import { Grid, withStyles, useTheme } from "@material-ui/core";
import { Editor, CollaborativeEditor } from "@flozy/editor";
import "./editor_override.css";
import OverrideStyle from "./OverrideStyle";
import { isMobile } from "react-device-detect";

const FlozyEditor = (props) => {
  const {
    editorRef,
    id,
    classes,
    className,
    content_status,
    content,
    onSave,
    otherProps,
    isCollaborative,
    ...rest
  } = props;
  const theme = useTheme()
  const Builder = isCollaborative ? CollaborativeEditor : Editor;
  return content_status ? (
    <Grid container className={isMobile? 'h-100':''}>
      <Grid
        item
        xs={12}
        className={`${classes.pageEditorRoot} pageEditorRoot ${
          className
            ? classes[className] || classes.editorRoot
            : classes.editorRoot
        } h-100`}
      >
        <Builder
          ref={editorRef}
          id={id}
          content={content}
          onSave={onSave}
          otherProps={otherProps}
          theme={theme}
          {...rest}
        />
      </Grid>
    </Grid>
  ) : null;
};

FlozyEditor.defaultProps = {
  toolbarOptions: {
    removeOptions: [],
    showOptions: [],
    toolbarPosition: 'top-left'
  }
}

export default withStyles(OverrideStyle)(FlozyEditor);
